import React, { PropsWithChildren, useRef, useState } from 'react';
import defaultImage from './default';
import { Button, Container, HiddenInput } from './style';

interface IProps {
  onChange: (result: any) => void;
  image: string;
}

const ImagePicker = (props: PropsWithChildren<IProps>) => {
  const inputRef = useRef<HTMLInputElement>();
  const [buster, setBuster] = useState(`?${Math.random()}`);

  let imageToShow = props.image || defaultImage;
  if (imageToShow && imageToShow.startsWith('http')) {
    imageToShow += buster;
  }

  const handleChange = (val: Blob) => {
    const reader = new FileReader();
    reader.onload = () => {
      setBuster(`?${Math.random()}`);
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width !== 820 || height !== 820) {
          alert('The image must be 820 by 820');
        } else {
          props.onChange(reader.result);
        }
      };
    };
    reader.readAsDataURL(val);
  };

  const onButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onChange(undefined);
  };

  return (
    <Container onClick={() => inputRef.current!.click()} style={{ backgroundImage: `url(${imageToShow})` }}>
      {props.image && <Button onClick={onButtonClick}>X</Button>}
      <HiddenInput type="file" ref={(r) => (inputRef.current = r!)} onChange={(e) => handleChange(e.target.files![0])} accept="image/*" />
    </Container>
  );
};

export default ImagePicker;
