import styled from 'styled-components';
import { spacing, colours, fonts } from '../../styles/theme';

export const ContainerInputs = styled.div`
  max-width: 250px;
  width: 60%;
  height: 230px;
  margin-top: ${spacing};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Logo = styled.img`
  height: 100px;
  width: 100px;
  margin-top: -5%;
`;

export const Title = styled.p`
  color: ${colours.text.primary};
  font-size: 16px;
  font-family: ${fonts.bold};
  text-align: center;
  margin-bottom: ${spacing * 3}px;
`;
