export enum Errors {
  TicketsUnavailable = 'Tickets Unavailable',
  TicketsTimeLimit = 'Tickets Time Limit',
  TicketsOnlyOne = 'Only One Ticket',
  TicketsTooFast = 'Tried to buy tickets too fast',
  TicketRejected = 'Ticket has been rejected',
  TicketRedeemed = 'Ticket already redeemed',
  CouponRedeemed = 'Offer already redeemed',

  TicketNotToday = 'Ticket not for today',
  CouponUnavailable = 'Coupon Unavailable',
  CouponTimeLimit = 'Coupon Time Limit',
  CouponOnlyOne = 'Only One Coupon',
  PrizeUnavailable = 'Prize Unavailable',
  PrizeTimeLimit = 'Prize Time Limit',
  NotEnoughPoints = 'Not Enough Points',
  NotFound = 'Not Found',
  AlreadyExists = 'Already Exists',
  PaymentError = 'Payment Error',
  Underage = 'Underage',
  NotLogged = 'Not Logged',
  Unexpected = 'Unexpected',
  UsingAnotherProvider = 'Using Another Provider',
  WrongPassword = 'Wrong Password',
  IncorrectRequest = 'Incorrect Request',
  Unauthorized = 'Unauthorized access',
}
