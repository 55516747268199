import styled from 'styled-components';
import { colours, fonts, spacing } from '../../../styles/theme';

export const Container = styled.div<{ type: string }>`
  width: 360px;
  padding: ${spacing}px;
  border: 2px solid ${colours.grey};
  border-radius: ${spacing}px;
  height: 80px;
  position: fixed;
  bottom: 50%;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${p => (p.type === 'alert' ? '#e60000' : p.type === 'success' ? '#008000' : colours.white)};
  color: ${p => (p.type === '' ? colours.text : colours.white)};
`;

export const Message = styled.p`
  font-family: ${fonts.bold};
`;
