import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { colours, corners, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  width: ${isMobile ? '100%' : '250px'};
  height: ${isMobile ? '300px' : '200px'};
  background: ${colours.white};
  border-radius: ${corners}px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  position: relative;
  margin: ${spacing}px;
`;

export const HiddenInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  left: 100000px;
`;

export const Button = styled.div`
  height: 30px;
  width: 30px;
  background: ${colours.white};
  border-radius: 30px;
  color: ${colours.red};
  position: absolute;
  top: -15px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: ${fonts.bold};
  box-shadow: ${colours.shadows.level1};
`;
