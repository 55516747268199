export class Club {
  public id: number;
  public name: string;
  public email: string;

  constructor(json = {} as any) {
    this.id = json.id;
    this.name = json.name;
    this.email = json.email;
  }
}
