import { Formik } from 'formik';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import Button from '../../components/button';
import ImagePicker from '../../components/imagePicker';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Subtitle from '../../components/subtitle';
import { Col } from '../../components/table/style';
import TextArea from '../../components/textArea';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { GQLInvestigation } from '../../shared';
import { history } from '../../store';
import { colours } from '../../styles/theme';
import { LoadingContext } from '../../utils/loadingContext';
import { Container } from './style';
import { Half } from './style';

const getDataByID = gql`
  query investigateByID($userID: Int) {
    result: investigateByID(userID: $userID) {
      user {
        id
        firstName
        lastName
        email
        dateOfBirth
        regDate
        lastLogin
        appVersionNumber
        opSystem
        opSystemVersion
        deviceModel
        deviceManufacturer
        city
        college
        yearsOfStudy
        courseLenght
        faculty
        pushEnabled
        allowEmails
        profileComplete
      }
      sales {
        id
        quantity
        totalPrice
        bookingFee
        processingFee
        type
        last4
        stripeID
        hash
        redeemed
        event {
          id
          title
          startTime
        }
      }
      similarUsers {
        id
        firstName
        lastName
        email
        dateOfBirth
        regDate
        lastLogin
        appVersionNumber
        opSystem
        opSystemVersion
        deviceModel
        deviceManufacturer
        city
        college
        yearsOfStudy
        courseLenght
        faculty
        pushEnabled
        allowEmails
        profileComplete
      }
    }
  }
`;

const Investigate = (props: PropsWithChildren) => {
  const loadingCtx = useContext(LoadingContext);
  const [submitting, setSubmitting] = useState(false);
  const [userID, setUserID] = useState<string>();

  const getData = useQuery<{ result: GQLInvestigation }, { userID: number }>(getDataByID, {
    skip: !submitting,
    variables: { userID: Number.parseInt(userID!, 10) },
  });

  const result = getData.data && getData.data.result ? getData.data.result : null;

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>Investigate a bug report</Title>

        <Half style={{ marginTop: 32, marginBottom: 64, width: 300 }}>
          <TextInput
            autocomplete="off"
            dark
            label="User ID"
            name="userID"
            value={userID!}
            onChange={(e: any) => {
              setSubmitting(false);
              setUserID(e.target.value);
            }}
          />
          <Button text="Search by ID" onClick={() => setSubmitting(true)} colour={colours.green} style={{ height: 40, width: 200, marginLeft: 32 }} />
        </Half>

        {result && (
          <>
            <Row>
              <Half style={{ width: 400 }}>
                <Row style={{ flexDirection: 'column' }}>
                  <Title>User</Title>
                  <Subtitle>
                    <Subtitle bold>ID:</Subtitle> {result.user!.id}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Name:</Subtitle> {result.user!.firstName} {result.user!.lastName}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Email:</Subtitle> {result.user!.email}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Last Login:</Subtitle>
                    {result.user!.lastLogin ? moment(result.user!.lastLogin).format('HH:mm A DD/MM/YY') : 'Not logged in'}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Registration Date:</Subtitle>
                    {result.user!.regDate ? moment(result.user!.regDate).format('HH:mm A DD/MM/YY') : 'Not found'}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>App Version:</Subtitle> {result.user!.appVersionNumber}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>OP System:</Subtitle> {result.user!.opSystem}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>OP System Version:</Subtitle> {result.user!.opSystemVersion}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Device Manufacturer:</Subtitle> {result.user!.deviceManufacturer}
                  </Subtitle>
                  <Subtitle>
                    <Subtitle bold>Device Model:</Subtitle> {result.user!.deviceModel}
                  </Subtitle>
                </Row>
              </Half>

              {result.similarUsers && result.similarUsers.filter((u) => u!.email !== result.user!.email).length > 0 && (
                <>
                  <Half>
                    <Row style={{ flexDirection: 'column' }}>
                      <Title>Accounts that are similar</Title>
                      {result.similarUsers
                        .filter((u) => u!.email !== result.user!.email)
                        .map((u) => (
                          <>
                            <Row style={{ flexDirection: 'column' }}>
                              <Subtitle>
                                <Subtitle bold>ID:</Subtitle> {u!.id}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Name:</Subtitle> {u!.firstName} {u!.lastName}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Email:</Subtitle> {u!.email}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Last Login:</Subtitle>
                                {u!.lastLogin ? moment(u!.lastLogin).format('HH:mm A DD/MM/YY') : 'Not logged in'}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Registration Date:</Subtitle>
                                {u!.regDate ? moment(u!.regDate).format('HH:mm A DD/MM/YY') : 'Not found'}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>App Version:</Subtitle> {u!.appVersionNumber}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>OP System:</Subtitle> {u!.opSystem}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>OP System Version:</Subtitle> {u!.opSystemVersion}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Device Manufacturer:</Subtitle> {u!.deviceManufacturer}
                              </Subtitle>
                              <Subtitle>
                                <Subtitle bold>Device Model:</Subtitle> {u!.deviceModel}
                              </Subtitle>
                            </Row>
                          </>
                        ))}
                    </Row>
                  </Half>
                </>
              )}
            </Row>

            <Row>
              {result.sales && result.sales.length > 0 && (
                <>
                  <Half>
                    <Row style={{ flexDirection: 'column' }}>
                      <Title>Sales</Title>
                      {result.sales.map((s) => (
                        <>
                          <Row style={{ flexDirection: 'column' }}>
                            <Subtitle>
                              <Subtitle bold>Sale ID:</Subtitle> {s!.id}
                            </Subtitle>
                            <Subtitle>
                              <Subtitle bold>Event:</Subtitle> {s!.event!.title}
                            </Subtitle>
                            <Subtitle>
                              <Subtitle bold>Event Date:</Subtitle>
                              {s!.event!.startTime ? moment(s!.event!.startTime).format('HH:mm A DD/MM/YY') : 'Not found in'}
                            </Subtitle>
                            {s!.totalPrice && (
                              <Subtitle>
                                <Subtitle bold>Total price:</Subtitle> {s!.totalPrice! / 100} €
                              </Subtitle>
                            )}
                            {s!.processingFee && (
                              <Subtitle>
                                <Subtitle bold>Booking fee:</Subtitle> {s!.processingFee! / 100} €
                              </Subtitle>
                            )}
                            {s!.bookingFee && (
                              <Subtitle>
                                <Subtitle bold>Booking fee:</Subtitle> {s!.bookingFee! / 100} €
                              </Subtitle>
                            )}
                            <Subtitle>
                              <Subtitle bold>Quantity:</Subtitle> {s!.quantity}
                            </Subtitle>
                            <Subtitle>
                              <Subtitle bold>Ticket type:</Subtitle> {s!.type}
                            </Subtitle>
                            <Subtitle>
                              <Subtitle bold>Last 4:</Subtitle> {s!.last4}
                            </Subtitle>
                            <Subtitle>
                              <Subtitle bold>Has been redeemed?:</Subtitle>{' '}
                              <span style={{ color: s!.redeemed ? colours.green : colours.red }}>{s!.redeemed ? 'Yes' : 'No'}</span>
                            </Subtitle>
                          </Row>
                        </>
                      ))}
                    </Row>
                  </Half>
                </>
              )}
            </Row>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Investigate;
