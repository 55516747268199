import styled from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  padding: ${spacing}px;
  background: ${colours.blue};
  height: 60px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
`;

export const SubBar = styled.div`
  width: 100%;
  padding: ${spacing}px;
  background: #6e7a86;
  height: 29px;
  position: fixed;
  top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Clubs = styled.div`
  width: 70%;
  overflow-x: auto;
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  color: ${colours.text.primary};
  font-family: ${fonts.bold};
  font-size: 12px;
`;

export const Link = styled.span<{ selected: boolean }>`
  color: ${(p) => (p.selected ? colours.yellow : colours.white)};
  font-family: ${fonts.bold};
  cursor: pointer;
  font-size: 12px;
  margin-right: 80px;
  min-width: 150px;
`;

export const Logo = styled.img`
  height: 40px;
  width: 40px;
  margin-left: ${spacing * 2}px;
  cursor: pointer;
`;
