import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colours, spacing } from '../styles/theme';

const Text = styled.h3`
  color: ${colours.blue}fa;
  margin: 0;
  margin-left: ${spacing * 2}px;
`;

const BoldOne = styled.span`
  color: ${colours.blue}fa;
  margin: 0;
  font-weight: bold;
`;

const Subtitle = (props: PropsWithChildren<{ bold?: boolean }>) => {
  return props.bold ? <BoldOne>{props.children}</BoldOne> : <Text>{props.children}</Text>;
};

export default Subtitle;
