import gql from 'graphql-tag';
import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import logo from '../../assets/logoWhite.svg';
import Button from '../../components/button';
import Page from '../../components/page';
import TextInput from '../../components/textInput';
import { Errors } from '../../shared';
import { history } from '../../store';
import { ContainerInputs } from '../login/style';
import { Logo, Title } from './style';
import BasePopUp from '../../components/popups/base';
import { IPopupBase } from '../../components/popups/base/base';

interface IProps {
  match: any;
}

const RESET_PASSWORD = gql`
  mutation resetPassword($userID: Int, $password: String, $type: String) {
    success: resetPassword(userID: $userID, password: $password, type: $type) {
      id
    }
  }
`;

const VALID_HASH = gql`
  query validHash($hash: String, $type: String) {
    valid: validHash(hash: $hash, type: $type) {
      id
      type
    }
  }
`;

const ResetPassword = (props: PropsWithChildren<IProps>) => {
  const successRef = useRef<IPopupBase>();
  const alertRef = useRef<IPopupBase>();
  const [user, setUser] = useState({ id: 0, type: 'User' });
  const [alertError, setError] = useState('');
  const { data, loading, error } = useQuery<{ valid: { id: number; type: string } }>(VALID_HASH, {
    variables: { hash: props.match.params.hash, type: props.match.params.type },
  });
  useEffect(() => {
    if (error) {
      const code = error.graphQLErrors[0] && error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code;
      if (code === Errors.NotFound) {
        history.replace('/');
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setUser(data.valid);
    }
  }, [data]);

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [reset, { error: resetError }] = useMutation(RESET_PASSWORD);

  const resetPassword = async () => {
    if (password1 !== password2) {
      setError("The password don't match");
      alertRef!.current!.open();
      setTimeout(() => {
        alertRef!.current!.close();
      }, 3000);
      return;
    }
    if (password1.length < 6 || password2.length < 6) {
      setError('The lenght must be at least 6 characters');
      alertRef!.current!.open();
      setTimeout(() => {
        alertRef!.current!.close();
      }, 3000);
      return;
    }

    const r = await reset({ variables: { userID: user.id, password: password1, type: props.match.params.type } });
    if (r.data) {
      successRef!.current!.open();
      setTimeout(() => {
        successRef!.current!.close();
        history.push('/login');
      }, 1500);
    }
    if (resetError) {
      alertRef!.current!.open();
      setTimeout(() => {
        alertRef!.current!.close();
      }, 3000);
    }
  };

  return (
    <Page blue>
      <ContainerInputs>
        <div>
          <Logo src={logo} />
          <Title>UNIPHI</Title>
        </div>
        <TextInput
          label={'Password'}
          type="password"
          name="password1"
          value={password1}
          required
          onChange={(e: any) => setPassword1(e.target.value)}
        />
        <TextInput
          label={'Repeat Password'}
          type="password"
          name="password2"
          value={password2}
          required
          onChange={(e: any) => setPassword2(e.target.value)}
        />
        <Button onClick={() => resetPassword()} text="RESET PASSWORD" full />
      </ContainerInputs>
      <BasePopUp ref={successRef} message={'Password updated succesfully! Redirecting...'} type={'success'} />
      <BasePopUp ref={alertRef} message={alertError} type={'alert'} />
    </Page>
  );
};

export default ResetPassword;
