import styled from 'styled-components';
import { spacing, colours } from '../../styles/theme';
import { Link } from 'react-router-dom';

export const ContainerInputs = styled.div`
  max-width: 250px;
  width: 60%;
  height: 230px;
  margin-top: ${spacing};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Logo = styled.img`
  height: 100px;
  width: 100px;
  margin-top: -5%;
`;

export const GoBackLink = styled(Link)`
  text-decoration: none;
  border-bottom: 0;
  color: ${colours.white};
`;
