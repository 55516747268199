export const corners = 4;
export const spacing = 8;
export const colours = {
  text: {
    primary: '#FFFFFF',
    dark: '#171717',
  },
  background: '#171717',
  yellow: '#FCAB26',
  blue: '#4A5968',
  grey: '#F2F2F2',
  red: '#FE1717',
  green: '#0CE13C',
  white: '#FFFFFF',
  black: '#171717',
  shadows: {
    level1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);',
    level2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);',
    level3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);',
  },
};

export const fonts = {
  medium: 'BrandonGrotesque-Medium',
  bold: 'BrandonGrotesque-Bold',
  regular: 'BrandonGrotesque-Regular',
  thin: 'BrandonGrotesque-Thin',
};
