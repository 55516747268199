import React, { PropsWithChildren, useState } from 'react';
import { Container, Input, Line, Text } from './style';

interface IProps {
  label: string;
  value: string | number;
  dark?: boolean;
  [x: string]: any;
}
const TextInput = (props: PropsWithChildren<IProps>) => {
  const [isFocused, setFocused] = useState(false);
  const isDate = props.type && props.type.indexOf('date') > -1;
  return (
    <Container>
      <Text
        className={isFocused || ((props.value || props.value === 0) && props.value.toString().length >= 1) || isDate ? 'focused' : ''}
        dark={props.dark}
        isDate={isDate}>
        {props.label}
      </Text>
      <Input
        {...props}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
      <Line className={isFocused ? 'focused' : ''} />
    </Container>
  );
};

export default TextInput;
