import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Button from '../../components/button';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Select from '../../components/select';
import TextArea from '../../components/textArea';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { Coupon, Event, GQLPushNotification } from '../../shared/';
import { colours } from '../../styles/theme';
import { Container, Content, Half, Quarter } from './style';

const selectValuesTopic = [{ name: 'everyone' }];
const selectValuesType = [{ name: 'general' }, { name: 'event' }, { name: 'offer' }];
const DATA = gql`
  {
    events: getEvents {
      id
      startTime
      title
      description
    }
    offers: getCoupons {
      id
      startDate
      title
      description
    }
  }
`;
const SEND_PUSH_NOTIFICATION = gql`
  mutation sendPushNotification($notification: PushNotificationInput, $type: String, $id: Int, $topic: String) {
    sendPushNotification(notification: $notification, type: $type, id: $id, topic: $topic) {
      id
      title
      body
    }
  }
`;

const PushNotifications = () => {
  const [sendPushNotification] = useMutation<{ notification: GQLPushNotification }>(SEND_PUSH_NOTIFICATION);

  const [topic, setTopic] = useState({ name: 'everyone' });
  const [type, setType] = useState({ name: 'general' });
  const [event, setEvent] = useState<Coupon | Event | null>();

  const [selectValuesShow, setselectValuesShow] = useState<Coupon[] | Event[] | []>([]);
  const res = useQuery(DATA);
  const events = (res.data && res.data.events) || [];
  const offers = (res.data && res.data.offers) || [];

  useEffect(() => {
    setEvent(null);
    if (type.name === 'event') {
      setselectValuesShow(events);
    } else if (type.name === 'offer') {
      setselectValuesShow(offers);
    } else if (type.name === 'general') {
      setselectValuesShow([]);
    }
  }, [type]);

  const sendPush = async (n: { body?: string; title?: string }) => {
    if (!type) return alert('The type is missing');
    try {
      if (type.name === 'event' || type.name === 'offer') {
        if (!event) return alert(`The ${type.name} is missing`);
        await sendPushNotification({
          variables: { notification: { body: n.body, title: n.title }, topic: topic.name, type: type.name, id: event.id },
        });
      } else {
        if (!n.title) return alert('The title is missing');
        if (!n.body) return alert('The description is missing');
        if (!topic) return alert('The topic is missing');

        await sendPushNotification({ variables: { notification: { ...n }, topic: topic.name } });
      }
      alert('Push notification sent 👍');
    } catch (error) {
      alert('Something went wrong 👎');
    }
  };
  const generalPushNotification = (values: any, handleChange: any) => {
    return (
      <>
        <Row>
          <Half>
            <TextInput autocomplete="off" dark label="TITLE" name="title" value={values.title} onChange={handleChange} />
          </Half>
        </Row>
        <Row>
          <TextArea autocomplete="off" dark label="DESCRIPTION" name="body" onChange={handleChange} value={values.body} />
        </Row>
      </>
    );
  };
  const eventOrOfferPush = (values: any, handleChange: any, resetForm: any) => {
    return (
      <>
        <Row>
          <Select
            data={selectValuesShow}
            width={500}
            label={`SELECT ${type.name}`}
            keyProp="title"
            onSelect={(i: any) => {
              setEvent(i);
              resetForm({ title: i.title, body: i.description });
            }}
            selectedValue={event}
          />
        </Row>
        {event && generalPushNotification(values, handleChange)}
      </>
    );
  };
  return (
    <Page>
      <NavBar />
      <Container>
        <Title>Push notification</Title>

        <Formik initialValues={{ title: '', body: '' }} onSubmit={() => null}>
          {({ values, handleChange, resetForm }) => (
            <Content>
              <Row>
                <Half style={{ justifyContent: 'space-between' }}>
                  <Select data={selectValuesTopic} width={150} label="TO" keyProp="name" onSelect={(i: any) => setTopic(i)} selectedValue={topic} />
                  <Select
                    data={selectValuesType}
                    width={150}
                    label="TYPE"
                    keyProp="name"
                    onSelect={(i: any) => {
                      setType(i);
                      if (i.name === 'general') resetForm({ title: i.title, body: i.description });
                    }}
                    selectedValue={type}
                  />
                </Half>
              </Row>
              {type.name === 'general' ? generalPushNotification(values, handleChange) : eventOrOfferPush(values, handleChange, resetForm)}

              <Quarter>
                <Button text="Send" onClick={() => sendPush(values)} colour={colours.green} style={{ width: '100%', height: 40 }} />
              </Quarter>
            </Content>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default PushNotifications;
