import moment from 'moment';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Button from '../../components/button';
import Subtitle from '../../components/subtitle';
import { GQLSale, PaymentHelper } from '../../shared';
import { colours, fonts } from '../../styles/theme';
import { Half } from '../investigate/style';

interface IProps {
  sale: GQLSale;
  onRefund: () => void;
}

const Card = styled.div`
  box-shadow: ${colours.shadows.level1};
  padding: 8px;
  width: 350px;
  height: 300px;
  background: white;
  margin-right: 32px;
  margin-bottom: 32px;
  border-radius: 4px;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${colours.shadows.level2};
  }
`;

const Title = styled.p`
  font-family: ${fonts.bold};
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 8px;
  width: 80%;
  text-align: center;
`;

const Text = styled.span<{ bold?: boolean }>`
  font-family: ${fonts.medium};
  margin-bottom: 8px;
  font-weight: ${(p) => (!p.bold ? 'normal' : 'bold')};
  font-size: 16px;
  text-align: left;
`;

const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const TicketCard = ({ sale, onRefund }: PropsWithChildren<IProps>) => {
  return (
    <Card>
      <Title>{sale.event!.title}</Title>

      <Row>
        <Text>
          <Text bold>{sale.quantity}</Text> {sale.quantity! > 1 ? 'tickets' : 'ticket'}
        </Text>
        <Text bold>{sale.type} release</Text>
      </Row>

      <Row>
        <Half style={{ flexDirection: 'column' }}>
          <Text>
            <Text bold>Total: </Text> {PaymentHelper.getPriceFormatted(sale.totalPrice!)} €
          </Text>
          <Text>
            <Text bold>Booking: </Text> {PaymentHelper.getPriceFormatted(sale.bookingFee!)} €
          </Text>
          <Text>
            <Text bold>Stripe: </Text> {PaymentHelper.getPriceFormatted(sale.processingFee!)} €
          </Text>
        </Half>

        <Half style={{ flexDirection: 'column' }}>
          <Text>
            <Text bold>Last 4: </Text> {sale.last4}
          </Text>
          <Text>
            <Text bold>Redeemed: </Text> {sale.redeemed ? 'Yes' : 'No'}
          </Text>
          <Text>
            <Text bold>Date: </Text> {moment(sale.event!.startTime).format('DD/MM/YY')}
          </Text>
        </Half>
      </Row>

      <Row style={{ justifyContent: 'center', display: 'flex', marginTop: 32 }}>
        <Button text="Delete Tickets" onClick={onRefund} />
      </Row>
    </Card>
  );
};

export default TicketCard;
