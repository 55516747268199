import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import Button from '../../components/button';
import DateTime from '../../components/datetime';
import ImagePicker from '../../components/imagePicker';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Select from '../../components/select';
import TextArea from '../../components/textArea';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { Club, GQLClub, Types } from '../../shared';
import { history } from '../../store';
import { colours, spacing } from '../../styles/theme';
import { EmployeeContext } from '../../utils/employeeContext';
import { EventContext } from '../../utils/eventContext';
import { LoadingContext } from '../../utils/loadingContext';
import { Checkbox, Container, Content, Half, Label, LabelContent, Quarter } from './style';

const CITIES_QUERY = gql`
  query cities {
    cities: getCities {
      id
      name
    }
  }
`;
const CLUBS_QUERY = gql`
  query clubs {
    clubs: getClubs {
      id
      name
    }
  }
`;

const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: Int!) {
    deleteEvent(eventId: $eventId)
  }
`;

const SUBMIT_EVENT = gql`
  mutation insertEvent($event: EventInput) {
    insertEvent(event: $event) {
      id
    }
  }
`;

const EDIT_EVENT = gql`
  mutation updateEvent($event: EventInputUpdate) {
    updateEvent(event: $event) {
      id
    }
  }
`;

const getNumber = (prop: number | undefined) => {
  if (prop || prop === 0) return prop;
  else return '';
};

const Events = (props: PropsWithChildren) => {
  const eventCtx = useContext(EventContext);
  const loadingCtx = useContext(LoadingContext);
  const event = (eventCtx!.event as any) || {};
  const isEditing = event.id;
  const ctx = useContext(EmployeeContext);
  const citiesResult = useQuery(CITIES_QUERY);
  const clubsResult = useQuery(CLUBS_QUERY);
  const [insertEvent] = useMutation(SUBMIT_EVENT);
  const [deleteEvent] = useMutation(DELETE_EVENT);
  const [updateEvent] = useMutation(EDIT_EVENT);

  const cities = (citiesResult.data && citiesResult.data.cities) || [];
  const clubs = (clubsResult.data && clubsResult.data.clubs) || [];

  const [submitting, setSubmitting] = useState(false);
  const [city, setCity] = useState({ name: event.city });
  const [club, setClub] = useState<GQLClub>();
  const [image, setImage] = useState(event.image);
  const [featured, setFeatured] = useState(event.featured || false);
  const [free, setFree] = useState(event.free || false);
  const [startTime, setStartTime] = useState(event.startTime ? moment(event.startTime) : undefined);
  const [guestCheckInTimeLimit, setCheckInTimeLimit] = useState(event.guestCheckInTimeLimit ? moment(event.guestCheckInTimeLimit) : undefined);
  const [purchaseTimeLimit, setPurchaseTimeLimit] = useState(event.purchaseTimeLimit ? moment(event.purchaseTimeLimit) : undefined);

  useEffect(() => {
    if (ctx!.employee.type === Types.SuperAdmin) {
      setClub(clubs.find((c: Club) => c.id === event.clubID));
    }
  }, [clubs]);

  const isValid = (vals: any) => {
    if (!vals.title) return alert('The title is missing');
    if (!vals.description) return alert('The description is missing');
    if (!startTime) return alert('The date & time field is missing');
    if (!vals.guestQty && vals.guestQty !== 0) return alert('The guest list quantity is missing');
    if (!free) {
      if (!vals.firstReleaseQty && vals.firstReleaseQty !== 0) return alert('The first release quantity is missing');
      if (!vals.secondReleaseQty && vals.secondReleaseQty !== 0) return alert('The second release quantity is missing');
      if (!vals.thirdReleaseQty && vals.thirdReleaseQty !== 0) return alert('The third release quantity is missing');
      if (!vals.firstReleasePrice) return alert('The first release price is missing');
      if (!vals.secondReleasePrice) return alert('The second release price is missing');
      if (!vals.thirdReleasePrice) return alert('The third release price is missing');
    }
    if (!guestCheckInTimeLimit) return alert('The check in time limit is missing');
    if (!purchaseTimeLimit) return alert('The purchase time limit is missing');
    if (!image) return alert('The image is missing');
    if (!city) return alert('The city is missing');
    if (ctx!.employee.type === Types.SuperAdmin && !club) return alert('The club is missing');
    return true;
  };

  const onCreate = async (vals: any, draft: boolean) => {
    if (submitting) return;
    setSubmitting(true);
    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await insertEvent({
          variables: {
            event: {
              draft,
              free,
              image,
              featured,
              city: city.name,
              title: vals.title,
              description: vals.description,
              guestQty: vals.guestQty,
              firstReleaseQty: free ? 0 : vals.firstReleaseQty,
              secondReleaseQty: free ? 0 : vals.secondReleaseQty,
              thirdReleaseQty: free ? 0 : vals.thirdReleaseQty,
              guestCheckInTimeLimit: guestCheckInTimeLimit!.toISOString(),
              purchaseTimeLimit: purchaseTimeLimit!.toISOString(),
              startTime: startTime!.toISOString(),
              firstReleaseCents: free ? 0 : vals.firstReleasePrice * 100,
              secondReleaseCents: free ? 0 : vals.secondReleasePrice * 100,
              thirdReleaseCents: free ? 0 : vals.thirdReleasePrice * 100,
              clubId: club && club.id ? club.id : ctx!.selectedClub ? ctx!.selectedClub.id : null,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
        } else {
          loadingCtx!.setLoading(false);
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        alert(`Something went wrong: ${JSON.stringify(e)}`);
        loadingCtx!.setLoading(false);
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onUpdate = async (vals: any, draft: boolean) => {
    if (submitting) return;
    setSubmitting(true);

    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await updateEvent({
          variables: {
            event: {
              image,
              draft,
              featured,
              free,
              id: event.id,
              city: city.name,
              title: vals.title,
              description: vals.description,
              guestQty: vals.guestQty,
              firstReleaseQty: free ? 0 : vals.firstReleaseQty,
              secondReleaseQty: free ? 0 : vals.secondReleaseQty,
              thirdReleaseQty: free ? 0 : vals.thirdReleaseQty,
              guestCheckInTimeLimit: guestCheckInTimeLimit!.toISOString(),
              purchaseTimeLimit: purchaseTimeLimit!.toISOString(),
              startTime: startTime!.toISOString(),
              firstReleaseCents: free ? 0 : Math.round(vals.firstReleasePrice * 100),
              secondReleaseCents: free ? 0 : Math.round(vals.secondReleasePrice * 100),
              thirdReleaseCents: free ? 0 : Math.round(vals.thirdReleasePrice * 100),
              clubId: club && club.id ? club.id : ctx!.selectedClub ? ctx!.selectedClub.id : null,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        alert(`Something went wrong: ${JSON.stringify(e)}`);
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onDelete = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);
    if (window.confirm('Are you sure? The event will be permanently deleted')) {
      try {
        loadingCtx!.setLoading(true);
        await deleteEvent({
          variables: {
            eventId: event.id,
          },
        });
        loadingCtx!.setLoading(false);
        setSubmitting(false);
        ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        alert(`Something went wrong: ${JSON.stringify(e)}`);
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>{isEditing ? 'Edit ' : 'Create '} event</Title>

        <Formik
          initialValues={{
            title: event.title || '',
            description: event.description || '',
            guestQty: getNumber(event.guestQty),
            firstReleaseQty: getNumber(event.firstReleaseQty),
            secondReleaseQty: getNumber(event.secondReleaseQty),
            thirdReleaseQty: getNumber(event.thirdReleaseQty),
            firstReleasePrice: event.firstReleaseCents / 100 || '',
            secondReleasePrice: event.secondReleaseCents / 100 || '',
            thirdReleasePrice: event.thirdReleaseCents / 100 || '',
          }}
          onSubmit={() => null}>
          {({ values, handleChange }) => (
            <Content>
              <Row>
                <Half>
                  <TextInput autocomplete="off" dark label="EVENT TITLE" name="title" value={values.title} onChange={handleChange} />
                </Half>
                <Half>
                  <Select data={cities} label="City" keyProp="name" onSelect={(i: any) => setCity(i)} selectedValue={city} />
                  <div style={{ width: isMobile ? 170 : 250, marginLeft: spacing }}>
                    <DateTime
                      dark
                      label="DATE & TIME"
                      name="startTime"
                      value={startTime && startTime.format ? startTime.format('DD-MM-YYYY HH:mm') : ''}
                      onChange={(d: moment.Moment) => setStartTime(d)}
                      large
                    />
                  </div>
                </Half>
              </Row>

              <Row>
                <Half>
                  <TextArea autocomplete="off" dark label="EVENT DESCRIPTION" name="description" onChange={handleChange} value={values.description} />
                </Half>

                <Half>
                  {!isMobile && <Quarter style={{ minWidth: 200, maxWidth: 200 }} />}
                  <ImagePicker onChange={(v) => setImage(v)} image={image} />
                </Half>
              </Row>

              <Row>
                <Half>
                  <Quarter>
                    <TextInput
                      autocomplete="off"
                      dark
                      label="GUEST QTY"
                      name="guestQty"
                      value={values.guestQty}
                      onChange={handleChange}
                      type="number"
                      min={0}
                    />
                  </Quarter>
                  {!free && (
                    <>
                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="1st RELEASE QTY"
                          name="firstReleaseQty"
                          type="number"
                          value={values.firstReleaseQty}
                          onChange={handleChange}
                          min={0}
                        />
                      </Quarter>

                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="2nd RELEASE QTY"
                          name="secondReleaseQty"
                          value={values.secondReleaseQty}
                          type="number"
                          min={0}
                          onChange={handleChange}
                        />
                      </Quarter>

                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="3rd RELEASE QTY"
                          name="thirdReleaseQty"
                          value={values.thirdReleaseQty}
                          type="number"
                          min={0}
                          onChange={handleChange}
                        />
                      </Quarter>
                    </>
                  )}
                </Half>

                <Half>
                  <div style={{ maxWidth: 200 }}>
                    <DateTime
                      dark
                      label="PURCHASE LIMIT"
                      name="purchaseTimeLimit"
                      value={purchaseTimeLimit && purchaseTimeLimit.format ? purchaseTimeLimit.format('DD-MM-YYYY HH:mm') : ''}
                      onChange={(d: moment.Moment) => setPurchaseTimeLimit(d)}
                    />
                  </div>
                </Half>
              </Row>

              <Row>
                <Half>
                  <Quarter>
                    <LabelContent>
                      <Checkbox checked={free} type="checkbox" id={'free'} onChange={(e) => setFree(e.target.checked)} />
                      <Label htmlFor={'free'}>Free</Label>
                    </LabelContent>
                  </Quarter>
                  {!free && (
                    <>
                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="1st RELEASE PRICE"
                          name="firstReleasePrice"
                          type="number"
                          min={1}
                          value={values.firstReleasePrice}
                          onChange={handleChange}
                        />
                      </Quarter>

                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="2nd RELEASE PRICE"
                          name="secondReleasePrice"
                          min={1}
                          value={values.secondReleasePrice}
                          type="number"
                          onChange={handleChange}
                        />
                      </Quarter>

                      <Quarter>
                        <TextInput
                          autocomplete="off"
                          dark
                          label="3rd RELEASE PRICE"
                          min={1}
                          name="thirdReleasePrice"
                          value={values.thirdReleasePrice}
                          type="number"
                          onChange={handleChange}
                        />
                      </Quarter>
                    </>
                  )}
                </Half>

                <Half>
                  <div style={{ maxWidth: 200 }}>
                    <DateTime
                      dark
                      label="GUEST CHECK IN TIME LIMIT"
                      name="guestCheckInTimeLimit"
                      value={guestCheckInTimeLimit && guestCheckInTimeLimit.format ? guestCheckInTimeLimit.format('DD-MM-YYYY HH:mm') : ''}
                      onChange={(d: moment.Moment) => setCheckInTimeLimit(d)}
                    />
                  </div>
                </Half>
              </Row>
              <Row>
                {ctx!.employee.type === Types.SuperAdmin && (
                  <Half>
                    <Select width={500} data={clubs} label="Club" keyProp="name" onSelect={(i: any) => setClub(i)} selectedValue={club} />
                  </Half>
                )}
                {ctx!.employee.type === Types.SuperAdmin && (
                  <LabelContent>
                    <Checkbox checked={featured} type="checkbox" id={'featured'} onChange={(e) => setFeatured(e.target.checked)} />
                    <Label htmlFor={'featured'}>Featured</Label>
                  </LabelContent>
                )}
              </Row>
              <Row>
                {isEditing && event.draft && (
                  <Quarter style={{ marginRight: isMobile ? 25 : 8 }}>
                    <Button text="DELETE" onClick={() => onDelete(values)} colour={colours.red} style={{ height: 40, width: '100%' }} />
                  </Quarter>
                )}
                <Quarter style={{ marginRight: isMobile ? 25 : 8 }}>
                  {!isEditing && <Button text="SAVE DRAFT" onClick={() => onCreate(values, true)} style={{ height: 40, width: '100%' }} />}
                  {isEditing && !event.draft && (
                    <Button text="DELETE" onClick={() => onDelete(values)} colour={colours.red} style={{ height: 40, width: '100%' }} />
                  )}
                  {isEditing && event.draft && (
                    <Button text="UPDATE DRAFT" onClick={() => onUpdate(values, true)} style={{ width: '100%', height: 40 }} />
                  )}
                </Quarter>
                <Quarter>
                  {isEditing && !event.draft && (
                    <Button text="UPDATE" onClick={() => onUpdate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                  {isEditing && event.draft && (
                    <Button text="PUSH LIVE" onClick={() => onUpdate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                  {!isEditing && (
                    <Button text="PUSH LIVE" onClick={() => onCreate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                </Quarter>
              </Row>
            </Content>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default Events;
