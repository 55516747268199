import React, { createContext, useState } from 'react';
import { User } from '../shared';

interface IState {
  user: User;
  setUser: (event: User | null) => void;
}

export const UserContext = createContext<IState | null>(null);

const UserProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  return <UserContext.Provider value={{ user, setUser }} {...props} />;
};
export default UserProvider;
