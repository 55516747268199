// import client from '../../utils/http';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import logo from '../../assets/logoWhite.svg';
import Button from '../../components/button';
import Page from '../../components/page';
import TextInput from '../../components/textInput';
import { Employee, Types } from '../../shared';
import { history } from '../../store';
import { EmployeeContext } from '../../utils/employeeContext';
import { Container, ContainerInputs, ForgotPasswordLink, Logo, Title } from './style';

const MUTATION = gql`
  mutation employeeLogin($email: String, $password: String) {
    employee: employeeLogin(email: $email, password: $password) {
      email
      token
      role
      name
      type
      clubs {
        id
        name
      }
    }
  }
`;
const Login = () => {
  const [email, setEmail] = useState(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? 'events@boonagency.ie' : '');
  const [password, setPassword] = useState(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? '1234' : '');
  const [login] = useMutation<{ employee: Employee }>(MUTATION);
  const ctx = useContext(EmployeeContext);

  const signIn = async () => {
    if (!email || !password) return;
    try {
      const r = await login({ variables: { email, password } });
      if (r.data) {
        const employee = new Employee(r.data.employee);
        ctx!.setEmployee(employee);
        ctx!.setAuth(true);
        localStorage.setItem('jwtToken', employee.token);

        if (employee.type === Types.SuperAdmin) {
          history.replace('/admin');
        } else {
          history.replace('/');
        }
      } else {
        alert('Incorrect email or password');
      }
    } catch (e: any) {
      if (e.graphQLErrors[0]) {
        alert(e.graphQLErrors[0].message);
      } else {
        alert(e);
      }
    }
  };

  return (
    <Page blue withPadding={false}>
      <Container>
        <Logo
          onDoubleClick={() => {
            // @ts-ignore
            // tslint:disable-next-line
            window['thisisgoingtobeanerror']();
          }}
          src={logo}
        />
        <Title>UNIPHI</Title>
        <ContainerInputs>
          <TextInput label={'Email'} type="text" name="email" value={email} required onChange={(e: any) => setEmail(e.target.value)} />
          <TextInput
            label={'Password'}
            type="password"
            name="password"
            value={password}
            required
            onChange={(e: any) => setPassword(e.target.value)}
            onKeyDown={(e: any) => (e.key === 'Enter' ? signIn() : null)}
          />

          <Button onClick={() => signIn()} text="LOG IN" full />
          <ForgotPasswordLink to={'/forgot-password'}>FORGOT YOUR PASSWORD?</ForgotPasswordLink>
        </ContainerInputs>
      </Container>
    </Page>
  );
};

export default Login;
