import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { fonts } from '../../styles/theme';
import { Col, RowWrapper } from './style';
import { IHeader } from './table';

const Bold = styled.span`
  font-family: ${fonts.bold};
`;
const UserRow = (props: PropsWithChildren<{ user: any; headers: IHeader[]; onClick: any }>) => {
  const { user, headers, onClick } = props;
  return (
    <RowWrapper onClick={onClick}>
      <Col style={{ width: headers[0].width }}>
        <Bold>{user.id}</Bold>
      </Col>
      <Col style={{ width: headers[1].width }}>
        <Bold>{user.email}</Bold>
      </Col>
      <Col style={{ width: headers[2].width }}>
        <Bold>{user.name}</Bold>
      </Col>
      <Col style={{ width: headers[3].width }}>
        <Bold>{user.type}</Bold>
      </Col>
      <Col style={{ width: headers[4].width }}>
        <Bold>{user.role}</Bold>
      </Col>
    </RowWrapper>
  );
};

export default UserRow;
