export class Types {
  public static EventList = 'EventList';
  public static MyTicket = 'MyTickets';
  public static CouponList = 'CouponList';
  public static MyCoupons = 'MyCoupons';
  public static MyPoints = 'MyPoints';
  public static Prizes = 'Prizes';
  public static User = 'User';
  public static Event = 'Event';
  public static Business = 'Business';
  public static CouponBusiness = 'CouponBusiness';
  public static Staff = 'Staff'; // Scan QR
  public static Employee = 'Employee'; // Create events
  public static CouponEmployee = 'CouponEmployee'; // Scan QR and create offers
  public static SuperAdmin = 'SuperAdmin'; // See and do everything
}

export enum CouponTypes {
  QR = 'QR',
  Code = 'Code',
}
