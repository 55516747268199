import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Types } from '../shared';

export interface IAdminRoute {
  component: any;
  isAuth: boolean;
  employee: any;
  [rest: string]: any;
}

class AdminRoute extends React.PureComponent<IAdminRoute, any> {
  private returnTo = '/';

  public render() {
    const { component: Component, rest, employee } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.canSee() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: this.returnTo,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  private canSee() {
    const { isAuth } = this.props;
    if (!isAuth) {
      this.returnTo = '/login';
      return false;
    } else if (this.props.employee.type === Types.SuperAdmin) {
      this.returnTo = '/';
      return isAuth;
    }
  }
}

export default AdminRoute;
