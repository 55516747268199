import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { fonts } from '../../styles/theme';
import { Col, RowWrapper, Status } from './style';
import { IHeader } from './table';

const Bold = styled.span`
  font-family: ${fonts.bold};
`;

const NoticeRow = (props: PropsWithChildren<{ notice: any; headers: IHeader[]; onClick: any }>) => {
  const { notice, headers, onClick } = props;

  const getDesc = (desc: string) => {
    if (!desc) return '';
    if (desc.length >= 80) return `${desc.substring(0, 80)}...`;

    return desc;
  };

  return (
    <RowWrapper onClick={onClick}>
      <Col style={{ width: headers[0].width }}>
        <Status isDraft={!notice.active} />
      </Col>
      <Col style={{ width: headers[1].width }}>
        <Bold>{notice.title}</Bold>
      </Col>
      <Col style={{ width: headers[2].width }}>
        <Bold>{getDesc(notice.description)}</Bold>
      </Col>
    </RowWrapper>
  );
};

export default NoticeRow;
