import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { colours, corners, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: ${corners}px;
  overflow: none;
  position: relative;
`;

export const Text = styled.span<{ dark?: boolean }>`
  color: ${colours.blue};
  font-family: ${fonts.bold};
  text-transform: uppercase;
  margin-bottom: ${spacing}px;
  font-size: ${isMobile ? 11 : 12}px;
  position: absolute;
  top: 8px;
  left: ${spacing}px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: none;
  &.focused {
    transform: translateY(-27px);
    color: ${(p) => (p.dark ? colours.blue : colours.text.primary)};
  }
`;

export const Input = styled.textarea`
  width: 100%;
  min-height: 200px;
  border: 0;
  outline: none;
  padding: ${spacing}px;
  border-radius: ${corners}px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  resize: none;
`;

export const Line = styled.div`
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 3px;
  background: ${colours.yellow}ad;
  position: relative;
  top: -2px;
  width: 0%;

  &.focused {
    width: 100%;
  }
`;
