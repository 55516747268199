import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { spacing } from '../styles/theme';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing * (isMobile ? 0.5 : 3)}px;
  margin-top: ${spacing * (isMobile ? 0.5 : 3)}px;
  flex-wrap: ${isMobile ? 'wrap' : 'nowrap'};
`;

const Row = (props: any) => {
  return <Wrapper {...props}>{props.children}</Wrapper>;
};

export default Row;
