import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div<{ full?: boolean; thin?: boolean; colour?: string }>`
  min-width: ${(p) => (p.full ? '100%' : '100px')};
  display: flex;
  height: ${(p) => (p.thin ? '35px' : 'auto')};
  justify-content: center;
  align-items: center;
  padding: ${spacing / 2}px;
  border-radius: 4px;
  background-color: ${(p) => (p.colour ? p.colour : colours.yellow)};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    transform: translateY(-1px);
  }
`;

export const Text = styled.span<{ colour?: string; fontSize?: number }>`
  color: ${(p) => (p.colour === colours.green || p.colour === colours.red ? colours.white : colours.blue)};
  font-family: ${fonts.bold};
  font-size: ${(p) => (isMobile ? 10 : p.fontSize ? p.fontSize : 13)}px;
`;
