import moment from 'moment';
import React, { PropsWithChildren, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import logo from '../../assets/logoWhite.svg';
import { Types } from '../../shared';
import { history } from '../../store';
import { colours } from '../../styles/theme';
import { EmployeeContext } from '../../utils/employeeContext';
import { Clubs, Container, Content, Link, Logo, SubBar, Text } from './style';

const NavBar = (props: PropsWithChildren) => {
  const ctx = useContext(EmployeeContext);
  const logOut = () => {
    ctx!.setEmployee(null);
    ctx!.setAuth(false);
    localStorage.removeItem('jwtToken');
    history.replace('/');
  };

  const emp = ctx!.employee || {};
  const goToHome = () => {
    if (history.location.pathname !== '/') emp.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
  };
  return (
    <>
      <Container>
        <Logo src={logo} onClick={goToHome} />
        {!isMobile && (
          <Clubs>
            {history.location.pathname === '/' &&
              emp.clubs.map((c) => (
                <Link selected={c.id === ctx!.selectedClub.id} onClick={() => ctx!.setSelectedClub(c)}>
                  {c.name}
                </Link>
              ))}
          </Clubs>
        )}
        <Content>
          <Text>Hi {emp.name}</Text>
          <Text style={{ color: colours.yellow, marginLeft: 8, marginRight: 8 }}> // </Text>
          <Text>{moment().format('ddd, Do of MMMM')}</Text>
          <Text style={{ color: colours.yellow, marginLeft: 8, marginRight: 8 }}> // </Text>
          <Text style={{ cursor: 'pointer' }} onClick={logOut}>
            Log out
          </Text>
        </Content>
      </Container>
      {isMobile && emp.clubs && emp.clubs.length > 1 && (
        <SubBar>
          <Clubs>
            {history.location.pathname === '/' &&
              emp.clubs.map((c) => (
                <Link selected={c.id === ctx!.selectedClub.id} onClick={() => ctx!.setSelectedClub(c)}>
                  {c.name}
                </Link>
              ))}
          </Clubs>
        </SubBar>
      )}
    </>
  );
};

export default NavBar;
