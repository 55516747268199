import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { spacing } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 95vw;
  max-width: 1400px;
  z-index: 300;
`;

export const Half = styled.div`
  &:not(:last-of-type) {
    margin-right: ${isMobile ? 0 : spacing}px;
  }
  display: flex;

  ${isMobile
    ? css`
        margin-bottom: ${spacing * 3}px;
        overflow-x: scroll;
      `
    : ''}
`;
