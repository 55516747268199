import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '../../components/button';
import ImagePicker from '../../components/imagePicker';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import TextArea from '../../components/textArea';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { GQLNotice, Types } from '../../shared';
import { history } from '../../store';
import { colours } from '../../styles/theme';
import { EmployeeContext } from '../../utils/employeeContext';
import { LoadingContext } from '../../utils/loadingContext';
import { NoticeContext } from '../../utils/noticeContext';
import { Quarter } from '../events/style';
import { CheckWrapper, Container, Content, Half, Label } from './style';

const SUBMIT_NOTICE = gql`
  mutation createNotice($notice: NoticeInput) {
    createNotice(notice: $notice) {
      id
    }
  }
`;

const DELETE_NOTICE = gql`
  mutation deleteNotice($noticeID: Int!) {
    deleteNotice(noticeID: $noticeID)
  }
`;

const EDIT_NOTICE = gql`
  mutation updateNotice($notice: NoticeInput) {
    updateNotice(notice: $notice) {
      id
    }
  }
`;

const Notices = (props: PropsWithChildren) => {
  const noticeCtx = useContext(NoticeContext);
  const loadingCtx = useContext(LoadingContext);
  const employeeCtx = useContext(EmployeeContext);
  const notice = (noticeCtx!.notice as GQLNotice) || {};
  const employee = (employeeCtx!.employee as any) || {};
  const isEditing = notice.id;
  const [image, setImage] = useState(notice.image);
  const [submitting, setSubmitting] = useState(false);

  const [insertNotice] = useMutation(SUBMIT_NOTICE);
  const [deleteNotice] = useMutation(DELETE_NOTICE);
  const [updateNotice] = useMutation(EDIT_NOTICE);

  const isValid = (vals: any) => {
    if (!vals.title) return alert('The title is missing');
    if (!vals.description) return alert('The price is missing');
    if (!image) return alert('The image is missing');

    return true;
  };

  const onCreate = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);
    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await insertNotice({
          variables: {
            notice: {
              image,
              title: vals.title,
              description: vals.description,
              active: vals.active,
              action: vals.action,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          noticeCtx!.setNotice(null);
          if (employee.type === Types.SuperAdmin) {
            history.replace('/admin');
          } else {
            history.replace('/');
          }
        } else {
          loadingCtx!.setLoading(false);
        }
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    }
  };

  const onUpdate = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);
    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await updateNotice({
          variables: {
            notice: {
              image,
              id: notice.id,
              title: vals.title,
              description: vals.description,
              active: vals.active,
              action: vals.action,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          noticeCtx!.setNotice(null);
          if (employee.type === Types.SuperAdmin) {
            history.replace('/admin');
          } else {
            history.replace('/');
          }
        } else {
          loadingCtx!.setLoading(false);
        }
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    }
  };

  const onDelete = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);
    if (window.confirm('Are you sure? The notice will be deleted')) {
      try {
        loadingCtx!.setLoading(true);
        await deleteNotice({
          variables: {
            noticeID: notice.id,
          },
        });
        noticeCtx!.setNotice(null);
        loadingCtx!.setLoading(false);
        setSubmitting(false);

        if (employee.type === Types.SuperAdmin) {
          history.replace('/admin');
        } else {
          history.replace('/');
        }
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    }
  };

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>{isEditing ? 'Edit ' : 'Create '} notice</Title>
        <Formik
          initialValues={{
            title: notice.title || '',
            description: notice.description || '',
            active: notice.active || false,
            action: notice.action || '',
          }}
          onSubmit={() => null}>
          {({ values, handleChange }) => (
            <Content>
              <Row>
                <Half>
                  <TextInput autocomplete="off" dark label="TITLE" name="title" value={values.title} onChange={handleChange} />
                </Half>
                <Half>
                  <Quarter>
                    <TextInput autocomplete="off" dark label="ACTION" name="action" value={values.action} onChange={handleChange} />
                  </Quarter>
                  <CheckWrapper>
                    <Label>Is active</Label>
                    <input type="checkbox" id="active" name="active" checked={values.active} onChange={handleChange} />
                  </CheckWrapper>
                </Half>
              </Row>

              <Row>
                <Half>
                  <TextArea autocomplete="off" dark label="DESCRIPTION" name="description" onChange={handleChange} value={values.description} />
                </Half>
                <Half>
                  <ImagePicker onChange={(v) => setImage(v)} image={image!} />
                </Half>
              </Row>

              <Quarter>
                {!isEditing && (
                  <Button
                    text="PUSH LIVE"
                    onClick={() => onCreate(values)}
                    colour={colours.green}
                    style={{ width: '100%', height: 40, marginLeft: 8 }}
                  />
                )}
                {isEditing && (
                  <>
                    <Button text="DELETE" onClick={() => onDelete(values)} colour={colours.red} style={{ width: '100%', height: 40 }} />
                    <Button
                      text="UPDATE"
                      onClick={() => onUpdate(values)}
                      colour={colours.green}
                      style={{ width: '100%', height: 40, marginLeft: 8 }}
                    />
                  </>
                )}
              </Quarter>
            </Content>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default Notices;
