import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import logoDarker from '../assets/logoHalfBlue.svg';
import logo from '../assets/logoHalfTransparent.svg';

import { colours } from '../styles/theme';

const Container = styled.div<{ blue?: boolean; withPadding: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  background-color: ${(p) => (p.blue ? colours.blue : colours.grey)};
  padding-top: ${(p) => (p.withPadding ? (isMobile ? 60 : 70) : 0)}px;
  overflow-y: auto;
`;

export const Logo = styled.img<{ blue?: boolean }>`
  height: 120%;
  width: 100%;
  position: fixed;
  pointer-events: none;
  left: -43%;
  opacity: ${(p) => (p.blue ? 1 : 0.8)};
`;

const Page = ({ blue = false, withPadding = true, children }: PropsWithChildren<{ blue?: boolean; withPadding?: boolean; children: any }>) => {
  return (
    <Container blue={blue} withPadding={withPadding}>
      <Logo src={blue ? logo : logoDarker} />
      {children}
    </Container>
  );
};

export default Page;
