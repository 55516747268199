import { ConnectedRouter } from 'connected-react-router';
import { gql, ApolloProvider, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import AdminRoute from './components/AdminRoute';
import ProtectedRoute from './components/ProtectedRoute';
import AdminPage from './pages/admin';
import DashboardPage from './pages/dashboard/dashboard';
import EventsPage from './pages/events';
import ForgotPasswordPage from './pages/forgotPassword';
import Investigate from './pages/investigate';
import LoginPage from './pages/login';
import Notices from './pages/notices';
import OffersPage from './pages/offers';
import PushNotifications from './pages/pushNotifications/pushNotifications';
import Refunds from './pages/refunds/refunds';
import ResetPasswordPage from './pages/resetPassword';
import Stats from './pages/stats';
import UsersPage from './pages/users';
import { Employee, Types } from './shared';
import { history, store } from './store';
import { colours } from './styles/theme';
import EmployeeProvider, { EmployeeContext } from './utils/employeeContext';
import EventProvider from './utils/eventContext';
import { apolloClient } from './utils/http';
import LoadingProvider, { LoadingContext } from './utils/loadingContext';
import NoticeProvider from './utils/noticeContext';
import OfferProvider from './utils/offerContext';
import UserProvider from './utils/userContext';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: BrandonGrotesque-Regular;
    box-sizing: border-box;
    font-size: 14px;

      @media (max-width: 1600px) {
        font-size: 12px;
      }

      @media only screen and (min-width: 375px) and (max-width: 812px) {
          font-size: 10px;
      }

      @media only screen and (min-width: 300px) and (max-width: 812px) {
          font-size: 9px;
      }
  }

  body {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8;
    /* color: ${colours.text.primary}; */
    -webkit-font-smoothing: antialiased;
  }

  .rdtPicker {
    .dow,  .rdtPrev, .rdtSwitch, .rdtNext, .rdtMonth, .rdtYear, .rdtTimeToggle, .rdtCounters {
      color: ${colours.blue} !important;
    }

    .rdtMonth.rdtActive, .rdtYear.rdtActive {
      color: white !important;
    }
    .rdtDay {
      color: ${colours.blue} !important;

      &.rdtOld, &.rdtNew {
        color: #ccc !important;
      }
    }
  }
`;

const MUTATION = gql`
  mutation rehydrate($token: String) {
    employeeRehydrate(token: $token) {
      email
      token
      role
      name
      type
      clubs {
        id
        name
      }
    }
  }
`;
const SET_EMPLOYEE = gql`
  mutation setEmployee($employee: EmployeeInput) {
    data: setEmployee(employee: $employee) @client {
      email
      token
      role
      name
    }
  }
`;
const App = () => {
  const ctx = useContext(EmployeeContext);
  const loadingCtx = useContext(LoadingContext);
  const [loaded, setLoaded] = useState(false);
  const [rehydrate] = useMutation(MUTATION);
  const [setEmployee] = useMutation(SET_EMPLOYEE);

  const checkValidRoutes = () => {
    const path = history.location.pathname;
    const resetPassword = /\/reset-password\/(User|Employee|Business)\/.*/;
    const forgotPassword = /\/forgot-password/;
    return forgotPassword.test(path) || resetPassword.test(path);
  };

  useEffect(() => {
    if (checkValidRoutes()) {
      setLoaded(true);
      return;
    }
    const rehydrateAux = async (t: string) => {
      try {
        const res = await rehydrate({ variables: { token: t } });
        if (res.data) {
          setEmployee({ variables: { employee: { ...(res.data as any).employeeRehydrate } } });
          const employee = new Employee((res.data as any).employeeRehydrate);
          ctx!.setEmployee(employee);
          ctx!.setAuth(true);
          let location;
          if (employee.type === Types.SuperAdmin) {
            location = ['/statistics', '/investigate', '/refunds'].includes(history.location.pathname) ? history.location.pathname : '/admin';
          } else {
            location = '/';
          }
          history.push(location);
          setLoaded(true);
        } else {
          history.push('/login');
          setLoaded(true);
        }
      } catch (error) {
        history.push('/login');
        setLoaded(true);
      }
    };

    const token = localStorage.getItem('jwtToken');
    if (token) {
      rehydrateAux(token);
    } else {
      setLoaded(true);
      history.push('/login');
    }
  }, []);

  return (
    <LoadingOverlay
      active={loadingCtx!.loading}
      spinner
      styles={{
        overlay: (base: any) => ({
          ...base,
        }),
        wrapper: (base: any) => ({
          height: '100vh',
          marginTop: !ctx!.auth ? null : isMobile ? 12 : null,
        }),
      }}>
      {loaded && (
        <Switch>
          <Route path="/reset-password/:type/:hash" component={ResetPasswordPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/login" component={LoginPage} />
          <AdminRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/investigate" component={Investigate} />
          <AdminRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/refunds" component={Refunds} />
          <AdminRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/statistics" component={Stats} />
          <AdminRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/admin" component={AdminPage} />
          <AdminRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/push-notifications" component={PushNotifications} />
          <ProtectedRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/events" component={EventsPage} />
          <ProtectedRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/offers" component={OffersPage} />
          <ProtectedRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/users" component={UsersPage} />
          <ProtectedRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/notices" component={Notices} />
          <ProtectedRoute isAuth={ctx!.auth} employee={ctx!.employee} path="/" component={DashboardPage} />
        </Switch>
      )}
    </LoadingOverlay>
  );
};

const AppWrapper = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <EmployeeProvider>
            <EventProvider>
              <OfferProvider>
                <NoticeProvider>
                  <UserProvider>
                    <LoadingProvider>
                      <GlobalStyle />
                      <App />
                    </LoadingProvider>
                  </UserProvider>
                </NoticeProvider>
              </OfferProvider>
            </EventProvider>
          </EmployeeProvider>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  );
};

export default AppWrapper;
