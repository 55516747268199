import React, { createContext, useState } from 'react';

interface IState {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  tabSelected: string;
  setTabSelected: (tab: string) => void;
}

export const LoadingContext = createContext<IState | null>(null);

const LoadingProvider = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<string>('events');
  return <LoadingContext.Provider value={{ loading, setLoading, tabSelected, setTabSelected }} {...props} />;
};
export default LoadingProvider;
