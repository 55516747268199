import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 95vw;
  max-width: 1200px;
  z-index: 300;
`;

export const CheckWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.label`
  color: ${colours.background};
  font-family: ${fonts.bold};
  margin-right: ${spacing * 2}px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colours.blue}17;
  padding: ${spacing * (isMobile ? 2 : 5)}px;
  ${isMobile &&
  css`
    padding-top: ${spacing * 3}px;
  `}
`;

export const Half = styled.div`
  width: ${!isMobile ? '50%' : '100%'};
  &:not(:last-of-type) {
    margin-right: ${isMobile ? 0 : spacing}px;
  }
  display: flex;

  ${isMobile
    ? css`
        margin-bottom: ${spacing * 3}px;
      `
    : ''}
`;
