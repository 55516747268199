import React, { PropsWithChildren, useState } from 'react';
import { Container, Input, Line, Text } from './style';

interface IProps {
  label: string;
  value: string;
  dark?: boolean;
  [x: string]: any;
}
const TextArea = (props: PropsWithChildren<IProps>) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <Container>
      <Text className={isFocused || (props.value && props.value.length > 1) ? 'focused' : ''} dark={props.dark}>
        {props.label}
      </Text>
      <Input
        {...props}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
      <Line className={isFocused ? 'focused' : ''} />
    </Container>
  );
};

export default TextArea;
