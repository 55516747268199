import moment from 'moment';
import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { fonts, spacing } from '../../styles/theme';
import { Col, RowWrapper, Status } from './style';
import { IHeader } from './table';

const Bold = styled.span`
  font-family: ${fonts.bold};
`;
const OfferRow = (props: PropsWithChildren<{ offer: any; headers: IHeader[]; onClick: any }>) => {
  const { offer: o, headers, onClick } = props;
  return (
    <RowWrapper onClick={onClick}>
      <Col style={{ width: headers[0].width }}>
        <Status isDraft={o.draft} />
      </Col>
      <Col style={{ width: headers[1].width, justifyContent: 'flex-start', marginLeft: spacing }}>{o.title}</Col>
      <Col style={{ width: headers[2].width }}>
        <Bold>{o.available}</Bold>
      </Col>
      <Col style={{ width: headers[3].width }}>
        <Bold>{o.taken}</Bold>
      </Col>
      <Col style={{ width: headers[4].width }}>
        <Bold>{o.redeemed}</Bold>
      </Col>
      <Col style={{ width: headers[5].width }}>{moment(o.expirationDate).format('dddd - DD.MM.YYYY')}</Col>
      {!isMobile && <Col style={{ width: headers[6].width }}>{o.location}</Col>}
    </RowWrapper>
  );
};

export default OfferRow;
