import React, { createContext, useState } from 'react';
import { Event } from '../shared';

interface IState {
  event: Event;
  setEvent: (event: Event | null) => void;
}

export const EventContext = createContext<IState | null>(null);

const EventProvider = (props: any) => {
  const [event, setEvent] = useState<Event | null>(null);
  return <EventContext.Provider value={{ event, setEvent }} {...props} />;
};
export default EventProvider;
