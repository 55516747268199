import React, { createContext, useState } from 'react';
import { GQLNotice } from '../shared';

interface IState {
  notice: GQLNotice;
  setNotice: (notice: GQLNotice | null) => void;
}

export const NoticeContext = createContext<IState | null>(null);

const NoticeProvider = (props: any) => {
  const [notice, setNotice] = useState<GQLNotice | null>(null);
  return <NoticeContext.Provider value={{ notice, setNotice }} {...props} />;
};
export default NoticeProvider;
