import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colours, spacing } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 95vw;
  max-width: 1200px;
  z-index: 300;
`;
export const Quarter = styled.div`
  ${isMobile
    ? css`
        width: 100%;
      `
    : css`
        width: 30%;
        max-width: 150px;
      `}
  &:not(:last-of-type) {
    margin-right: ${spacing}px;
  }
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colours.blue}17;
  padding: ${spacing * (isMobile ? 2 : 5)}px;
  ${isMobile &&
  css`
    padding-top: ${spacing * 3}px;
  `}
`;

export const Half = styled.div`
  width: ${!isMobile ? '50%' : '100%'};
  &:not(:last-of-type) {
    margin-right: ${isMobile ? 0 : spacing}px;
  }
  display: flex;

  ${isMobile
    ? css`
        margin-bottom: ${spacing * 3}px;
      `
    : ''}
`;
