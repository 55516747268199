import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export interface IProtectedRoute {
  component: any;
  isAuth: boolean;
  employee: any;
  [rest: string]: any;
}

class ProtectedRoute extends React.PureComponent<IProtectedRoute, any> {
  private returnTo = '/login';

  public render() {
    const { component: Component, rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.canSee() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: this.returnTo,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  private canSee() {
    const { isAuth } = this.props;
    if (!isAuth) {
      this.returnTo = '/login';
      return false;
    }

    this.returnTo = '/';
    return isAuth;
  }
}

export default ProtectedRoute;
