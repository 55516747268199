import gql from 'graphql-tag';
import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import logo from '../../assets/logoWhite.svg';
import Button from '../../components/button';
import Page from '../../components/page';
import BasePopUp, { IPopupBase } from '../../components/popups/base/base';
import TextInput from '../../components/textInput';
import { Types } from '../../shared';
import { ContainerInputs } from '../login/style';
import { GoBackLink, Logo } from './style';

const FORGOT_PASSWORD = gql`
  mutation sendEmailResetPassword($email: String, $type: String) {
    sendEmailResetPassword(email: $email, type: $type) {
      id
    }
  }
`;

const ForgotPassword = () => {
  const successRef = useRef<IPopupBase>();

  const [email, setEmail] = useState('');
  const [type, setType] = useState(Types.User);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const sendEmail = async () => {
    if (email !== '') {
      const { data } = await forgotPassword({ variables: { email, type } });
      successRef!.current!.open();
      setTimeout(() => {
        successRef!.current!.close();
      }, 1500);
    }
  };

  return (
    <Page blue>
      <ContainerInputs>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Logo src={logo} />
        </div>
        <select onChange={(e) => setType(e.target.value)}>
          <option value={Types.User}>User</option>
          <option value={Types.Employee}>Employee</option>
          <option value={Types.Business}>Business</option>
        </select>
        <TextInput label={'Email'} type="email" name="email" value={email} required onChange={(e: any) => setEmail(e.target.value)} />
        <Button onClick={() => sendEmail()} text="SEND EMAIL" full />
        <GoBackLink to={'/login'}>GO BACK</GoBackLink>
        <BasePopUp ref={successRef} message={'Mail sent succesfully!'} type={'success'} />
      </ContainerInputs>
    </Page>
  );
};

export default ForgotPassword;
