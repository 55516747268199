import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import Button from '../../components/button';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Table from '../../components/table';
import EventRow from '../../components/table/eventRow';
import OfferRow from '../../components/table/offerRow';
import Title from '../../components/title';
import { Types } from '../../shared';
import { history } from '../../store';
import { EmployeeContext } from '../../utils/employeeContext';
import { EventContext } from '../../utils/eventContext';
import { OfferContext } from '../../utils/offerContext';
import { Container } from './style';
import { getHeaders } from './utils';

export const EVENTS_QUERY = gql`
  {
    events: getScannerEvents {
      id
      startTime
      title
      guest
      first
      description
      second
      third
      guestQty
      free
      firstReleaseQty
      secondReleaseQty
      thirdReleaseQty
      firstReleaseCents
      secondReleaseCents
      thirdReleaseCents
      draft
      payout
      purchaseTimeLimit
      guestCheckInTimeLimit
      image
      sqip
      featured
      city
      clubID
    }
  }
`;

const OFFERS_QUERY = gql`
  {
    offers: getScannerOffers {
      id
      title
      description
      terms
      image
      expirationDate
      startDate
      businessID
      available
      taken
      redeemed
      total
      location
      draft
      validUntil
      quantity
      couponCategoriesID
      category {
        id
        name
      }
      city
      featured
      type
      code
    }
  }
`;

const headers = getHeaders();

const Dashboard = () => {
  const ctx = useContext(EmployeeContext);
  const eventCtx = useContext(EventContext);
  const offerCtx = useContext(OfferContext);

  const isEventEmployee = ctx!.employee && ctx!.employee.type === Types.Employee;
  const res = useQuery(isEventEmployee ? EVENTS_QUERY : OFFERS_QUERY, { fetchPolicy: 'no-cache' });
  const events = (res.data && res.data.events) || [];
  const offers = (res.data && res.data.offers) || [];

  return (
    <Page>
      <NavBar />
      <Container>
        <Row style={{ justifyContent: 'space-between' }}>
          <Title>{isEventEmployee ? 'Events' : 'Offers'}</Title>
          <Button
            text={isEventEmployee ? 'create event' : 'create offer'}
            onClick={() => {
              if (isEventEmployee) {
                eventCtx!.setEvent(null);
                history.push('/events');
              } else {
                offerCtx!.setOffer(null);
                history.push('/offers');
              }
            }}
            thin
          />
        </Row>
        <Table
          headers={isEventEmployee ? headers.events : headers.offers}
          renderRows={() => {
            if (isEventEmployee) {
              return events
                .filter((e: any) => e.clubID === ctx!.selectedClub.id)
                .map((e: any) => (
                  <EventRow
                    key={e.id}
                    event={e}
                    headers={headers.events}
                    onClick={() => {
                      eventCtx!.setEvent(e);
                      history.push('/events');
                    }}
                  />
                ));
            } else {
              return offers.map((o: any) => (
                <OfferRow
                  key={o.id}
                  offer={o}
                  headers={headers.offers}
                  onClick={() => {
                    offerCtx!.setOffer(o);
                    history.push('/offers');
                  }}
                />
              ));
            }
          }}
        />
      </Container>
    </Page>
  );
};

export default Dashboard;
