import { ResponsivePie } from '@nivo/pie';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Toggle } from 'react-toggle-component';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Subtitle from '../../components/subtitle';
import Title from '../../components/title';
import { Container, GraphContainer, Label, StatContainer, StatsWrapper, ToggleWrapper } from './style';

export const GET_STATS = gql`
  {
    stats: getStatisticsBackend
  }
`;

const Stats = () => {
  const [isSensitiveHidden, setSensitiveHidden] = useState(
    localStorage.getItem('isSensitiveHidden') ? (localStorage.getItem('isSensitiveHidden') === 'true' ? true : false) : false,
  );
  const { data } = useQuery(GET_STATS);
  const stats = data.stats || {
    sales: [],
    users: {
      profileCompleted: 0,
      profileNotCompleted: 0,
      allowEmails: 0,
      notAllowEmails: 0,
      total: 0,
    },
  };

  const getQty = (sales: any) => sales.map((s: any) => Number.parseInt(s.Quantity, 10)).reduce((a: number, b: number) => a + b, 0);
  const paidTickets = stats.sales.filter((s: any) => s.type !== 'Guest');
  const firstTickets = stats.sales.filter((s: any) => s.type === 'First');
  const secondTickets = stats.sales.filter((s: any) => s.type === 'Second');
  const thirdTickets = stats.sales.filter((s: any) => s.type === 'Third');
  const guestTickets = stats.sales.filter((s: any) => s.type === 'Guest');

  const ticketsQty = getQty(paidTickets);
  const guestTicketsQty = getQty(guestTickets);
  const firstQty = getQty(firstTickets);
  const secondQty = getQty(secondTickets);
  const thirdQty = getQty(thirdTickets);

  const throughSystem = stats.sales
    .map((s: any) => Number.parseFloat(s['Total price']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const throughSystemToday = stats.sales
    .filter((s: any) => s.date === moment().format('YYYY-MM-DD'))
    .map((s: any) => Number.parseFloat(s['Total price']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const throughSystemWeek = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('week'), moment().endOf('week')))
    .map((s: any) => Number.parseFloat(s['Total price']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const throughSystemMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('month'), moment().endOf('month')))
    .map((s: any) => Number.parseFloat(s['Total price']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const throughSystemLastMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')))
    .map((s: any) => Number.parseFloat(s['Total price']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const toVenues = stats.sales
    .map((s: any) => Number.parseFloat(s['Value to venue']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const toVenuesToday = stats.sales
    .filter((s: any) => s.date === moment().format('YYYY-MM-DD'))
    .map((s: any) => Number.parseFloat(s['Value to venue']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const toVenuesWeek = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('week'), moment().endOf('week')))
    .map((s: any) => Number.parseFloat(s['Value to venue']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const toVenuesMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('month'), moment().endOf('month')))
    .map((s: any) => Number.parseFloat(s['Value to venue']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const toVenuesLastMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')))
    .map((s: any) => Number.parseFloat(s['Value to venue']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const bookingFees = stats.sales
    .map((s: any) => Number.parseFloat(s['Booking Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const bookingFeesToday = stats.sales
    .filter((s: any) => s.date === moment().format('YYYY-MM-DD'))
    .map((s: any) => Number.parseFloat(s['Booking Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const bookingFeesWeek = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('week'), moment().endOf('week')))
    .map((s: any) => Number.parseFloat(s['Booking Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const bookingFeesMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().startOf('month'), moment().endOf('month')))
    .map((s: any) => Number.parseFloat(s['Booking Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const bookingFeesLastMonth = stats.sales
    .filter((s: any) => moment(s.date).isBetween(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')))
    .map((s: any) => Number.parseFloat(s['Booking Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  const stripeFees = stats.sales
    .map((s: any) => Number.parseFloat(s['Stripe Processing Fee']))
    .reduce((a: number, b: number) => a + b, 0)
    .toLocaleString(undefined, { maximumFractionDigits: 2 });

  return (
    <Page>
      <NavBar />
      <ToggleWrapper>
        <Label htmlFor="SensitiveToggle">
          Hide sensitive data
          <Toggle
            name="SensitiveToggle"
            rightBackgroundColor="#FCAB26"
            rightKnobColor="white"
            rightBorderColor="white"
            checked={isSensitiveHidden}
            onToggle={(e: any) => {
              setSensitiveHidden(e.target.checked);
              localStorage.setItem('isSensitiveHidden', e.target.checked.toString());
            }}
          />
        </Label>
      </ToggleWrapper>

      <Container>
        {!isSensitiveHidden && (
          <>
            <Row>
              <StatsWrapper>
                <StatContainer>
                  <Title>Through system</Title>
                  <Subtitle>Total: {throughSystem}€</Subtitle>
                  <Subtitle>Month: {throughSystemMonth}€</Subtitle>
                  <Subtitle>Last month: {throughSystemLastMonth}€</Subtitle>
                  <Subtitle>Week: {throughSystemWeek}€</Subtitle>
                  <Subtitle>Today: {throughSystemToday}€</Subtitle>
                </StatContainer>

                <StatContainer>
                  <Title>Generated to venues</Title>
                  <Subtitle>Total: {toVenues}€</Subtitle>
                  <Subtitle>Month: {toVenuesMonth}€</Subtitle>
                  <Subtitle>Last month: {toVenuesLastMonth}€</Subtitle>
                  <Subtitle>Week: {toVenuesWeek}€</Subtitle>
                  <Subtitle>Today: {toVenuesToday}€</Subtitle>
                </StatContainer>

                <StatContainer>
                  <Title>Booking Fees</Title>
                  <Subtitle>Total: {bookingFees}€</Subtitle>
                  <Subtitle>Month: {bookingFeesMonth}€</Subtitle>
                  <Subtitle>Last month: {bookingFeesLastMonth}€</Subtitle>
                  <Subtitle>Week: {bookingFeesWeek}€</Subtitle>
                  <Subtitle>Today: {bookingFeesToday}€</Subtitle>
                </StatContainer>

                <StatContainer>
                  <Title>Stripe Fees</Title>
                  <Subtitle>{stripeFees}€</Subtitle>
                </StatContainer>
              </StatsWrapper>
            </Row>
          </>
        )}

        <Row>
          <StatsWrapper>
            <StatContainer>
              <Title>Users</Title>
              <Subtitle>{stats.users.total}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Completed profile</Title>
              <Subtitle>{stats.users.profileCompleted}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Didn't complete profile</Title>
              <Subtitle>{stats.users.profileNotCompleted}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Allow emails</Title>
              <Subtitle>{stats.users.allowEmails}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Doesn't allow emails</Title>
              <Subtitle>{stats.users.notAllowEmails}</Subtitle>
            </StatContainer>
          </StatsWrapper>
        </Row>

        <Row>
          <StatsWrapper>
            <StatContainer>
              <Title>Paid Tickets</Title>
              <Subtitle>{ticketsQty}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Guest Tickets</Title>
              <Subtitle>{guestTicketsQty}</Subtitle>
            </StatContainer>

            <StatContainer>
              <Title>Total Tickets</Title>
              <Subtitle>{ticketsQty + guestTicketsQty}</Subtitle>
            </StatContainer>
          </StatsWrapper>
        </Row>

        <Row>
          <GraphContainer>
            <Title>Tickets types</Title>
            <ResponsivePie
              data={[
                {
                  id: 'guest',
                  label: 'Guest',
                  value: guestTicketsQty,
                },
                {
                  id: 'first',
                  label: 'First Release',
                  value: firstQty,
                },
                {
                  id: 'second',
                  label: 'Second Release',
                  value: secondQty,
                },
                {
                  id: 'third',
                  label: 'Third Release',
                  value: thirdQty,
                },
              ]}
              innerRadius={0.5}
              colors={{ scheme: 'nivo' }}
              animate={true}
            />
          </GraphContainer>
        </Row>
      </Container>
    </Page>
  );
};

export default Stats;
