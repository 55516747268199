import { Club } from './Club';
import { Role } from './Role';

export class Employee {
  public id: number;
  public name: string;
  public email: string;
  public token: string;
  public role: string;
  public password: string;
  public type: 'Employee' | 'CouponEmployee' | 'SuperAdmin';
  public clubs: Club[];

  constructor(json = {} as any) {
    this.id = json.id;
    this.name = json.name;
    this.email = json.email;
    this.token = json.token;
    this.role = json.role;
    this.password = json.password;
    this.type = json.type;
    this.clubs = (json.clubs || []).map((c: any) => new Club(c));
  }

  public isAdmin() {
    return this.role === Role.Admin || this.isSuperAdmin();
  }
  public isSuperAdmin() {
    return this.role === Role.SuperAdmin;
  }
}
