import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 95vw;
  max-width: 1200px;
  z-index: 300;
`;

export const Half = styled.div`
  width: ${!isMobile ? '50%' : '100%'};
  &:not(:last-of-type) {
    margin-right: ${isMobile ? 0 : spacing}px;
  }
  display: flex;

  ${isMobile
    ? css`
        margin-bottom: ${spacing * 3}px;
      `
    : ''}
`;
