import React, { createContext, useState } from 'react';
import { Coupon } from '../shared';

interface IState {
  offer: Coupon;
  setOffer: (offer: Coupon | null) => void;
}

export const OfferContext = createContext<IState | null>(null);

const OfferProvider = (props: any) => {
  const [offer, setOffer] = useState<Coupon | null>(null);
  return <OfferContext.Provider value={{ offer, setOffer }} {...props} />;
};
export default OfferProvider;
