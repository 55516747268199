import React, { PropsWithChildren } from 'react';
import { Container, Text } from './style';

interface IProps {
  text: string;
  full?: boolean;
  thin?: boolean;
  colour?: string;
  style?: any;
  onClick: (text: string) => void;
}
const Button = (props: PropsWithChildren<IProps>) => {
  return (
    <Container
      style={props.style}
      onClick={() => props.onClick(props.text.toLocaleLowerCase())}
      full={props.full}
      thin={props.thin}
      colour={props.colour}>
      <Text fontSize={props.style ? props.style.fontSize : null} colour={props.colour}>
        {props.text}
      </Text>
    </Container>
  );
};

export default Button;
