import styled from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div<{ full?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing}px;
`;

export const Col = styled.div<{ noBorder?: boolean }>`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colours.blue};

  border-right: ${(p) => (p.noBorder ? 0 : `1px solid ${colours.blue}12`)};
`;

export const HeaderText = styled.span`
  color: ${colours.blue};
  text-transform: uppercase;
  font-family: ${fonts.medium};
`;

export const Rows = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const RowWrapper = styled.div`
  padding: ${spacing}px;
  min-height: 50px;
  margin-bottom: ${spacing * 2}px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: ${colours.white};
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  &:hover {
    top: -1px;
  }
`;

export const Status = styled.div<{ isDraft: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${(p) => (p.isDraft ? colours.red : colours.green)};
`;
