import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;
export const Title = styled.p`
  color: ${colours.text.primary};
  font-size: 16px;
  font-family: ${fonts.bold};
  text-align: center;
  margin-bottom: ${spacing * 10}px;
`;

export const ContainerInputs = styled.div`
  max-width: 250px;
  width: 60%;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Logo = styled.img`
  height: 100px;
  width: 100px;
  margin-top: -5%;
`;

export const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
  border-bottom: 0;
  color: ${colours.white};
`;
