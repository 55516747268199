import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Title from '../../components/title';
import { colours } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 95vw;
  max-width: 1400px;
  z-index: 300;
`;

export const GraphContainer = styled.div`
  height: 200px;
  width: ${isMobile ? '100%' : '400px'};
`;

export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  right: 16px;
`;

export const Label = styled.label`
  font-weight: bold;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
`;
