import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colours, spacing } from '../styles/theme';

const Text = styled.h1`
  color: ${colours.blue};
  margin: 0;
  margin-left: ${spacing * 2}px;
`;

const Title = (props: PropsWithChildren) => {
  return <Text>{props.children}</Text>;
};

export default Title;
