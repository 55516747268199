import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '../../components/button';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { GQLSale } from '../../shared';
import { colours } from '../../styles/theme';
import TicketCard from './purchase';
import { Container, Half } from './style';

const GET_TICKETS = gql`
  query geTickets($email: String) {
    tickets: getTicketsByEmail(email: $email) {
      id
      quantity
      totalPrice
      bookingFee
      processingFee
      type
      last4
      stripeID
      redeemed
      user {
        id
        firstName
        lastName
        email
      }
      event {
        id
        title
        startTime
      }
    }
  }
`;

const REFUND_TICKETS = gql`
  mutation refundTickets($saleID: Int, $quantityToRemove: Int) {
    refundTickets(saleID: $saleID, quantityToRemove: $quantityToRemove)
  }
`;

const Tickets = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Refunds = () => {
  const [submitting, setSubmitting] = useState(false);
  const [email, setUserEmail] = useState('');

  const getData = useQuery<{ tickets: GQLSale[] }, { email: string }>(GET_TICKETS, {
    skip: !submitting,
    variables: { email },
  });

  const [refundTickets] = useMutation<any, { quantityToRemove: number; saleID: number }>(REFUND_TICKETS);
  const result = getData.data && getData.data.tickets ? getData.data.tickets : ([] as GQLSale[]);

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>Refunds</Title>

        <Half style={{ marginTop: 32, marginBottom: 64, width: 300 }}>
          <TextInput
            autocomplete="off"
            dark
            label="User's Email"
            name="email"
            value={email}
            onChange={(e: any) => {
              setSubmitting(false);
              setUserEmail(e.target.value);
            }}
          />
          <Button text="Get tickets" onClick={() => setSubmitting(true)} colour={colours.green} style={{ height: 40, width: 200, marginLeft: 32 }} />
        </Half>

        <Tickets>
          {result.map((t) => (
            <TicketCard
              key={t.id}
              sale={t}
              onRefund={async () => {
                const answer = prompt(`How many tickets do you want to refund? Between 1 and ${t.quantity}`) as string;
                const howMany = Number.parseInt(answer, 10);

                if (!answer || howMany === 0) return;

                if (isNaN(howMany)) {
                  return alert(`${answer} is not a number!`);
                }

                if (howMany > t.quantity!) {
                  return alert("Can't refund that many tickets");
                }

                try {
                  const res = await refundTickets({
                    variables: {
                      saleID: t.id!,
                      quantityToRemove: howMany,
                    },
                  });

                  if (res && res.data) {
                    getData.refetch();
                  } else {
                    alert('Something went wrong');
                    window.location.reload();
                  }
                } catch (e) {
                  alert('Something went wrong');
                  window.location.reload();
                }
              }}
            />
          ))}
        </Tickets>
      </Container>
    </Page>
  );
};

export default Refunds;
