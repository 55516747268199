import moment from 'moment';
import React, { PropsWithChildren, useState } from 'react';
import DT from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Container, Input, Line, Text } from './style';

const TypedDatetime = DT as React.ComponentType<
  DT.DatetimepickerProps & {
    renderInput?: (props: any, openCalendar: () => void, closeCalendar: () => void) => void;
  }
>;
interface IProps {
  label: string;
  value: string;
  dark?: boolean;
  large?: boolean;
  onlyDate?: boolean;
  [x: string]: any;
}

const TextInput = (props: PropsWithChildren<IProps>) => {
  const [isFocused, setFocused] = useState(false);
  const isDate = true;
  return (
    <Container>
      <Text className={'focused'} dark={props.dark} isDate={isDate}>
        {props.label}
      </Text>
      <TypedDatetime
        value={props.value}
        dateFormat="DD-MM-YYYY"
        timeFormat={props.onlyDate ? false : 'HH:mm'}
        onChange={(d) => {
          props.onChange(d as moment.Moment);
        }}
        renderInput={(p: any, openCalendar: any, closeCalendar: any) => (
          <Input
            {...p}
            large={props.large}
            onFocus={() => {
              openCalendar();
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
              closeCalendar();
            }}
          />
        )}
      />
      <Line className={isFocused ? 'focused' : ''} />
    </Container>
  );
};

export default TextInput;
