import { Formik } from 'formik';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import Button from '../../components/button';
import DateTime from '../../components/datetime';
import ImagePicker from '../../components/imagePicker';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Select from '../../components/select';
import TextArea from '../../components/textArea';
import TextInput from '../../components/textInput';
import Title from '../../components/title';
import { Club, Coupon, GQLBusiness, Types } from '../../shared';
import { CouponTypes } from '../../shared/Types';
import { history } from '../../store';
import { colours } from '../../styles/theme';
import { EmployeeContext } from '../../utils/employeeContext';
import { LoadingContext } from '../../utils/loadingContext';
import { OfferContext } from '../../utils/offerContext';
import { Checkbox, Container, Content, Half, Label, LabelContent, Quarter } from './style';

const DATA = gql`
  query data {
    cities: getCities {
      id
      name
    }
    categories: getCouponCategories {
      id
      name
    }
  }
`;
const BUSINESS_QUERY = gql`
  query businesses {
    businesses: getBusinesses {
      id
      name
    }
  }
`;

const DELETE_OFFER = gql`
  mutation deleteCoupon($couponID: Int!) {
    deleteCoupon(couponID: $couponID)
  }
`;

const SUBMIT_OFFER = gql`
  mutation insertCoupon($coupon: CouponInput) {
    insertCoupon(coupon: $coupon) {
      id
    }
  }
`;

const EDIT_OFFER = gql`
  mutation updateCoupon($coupon: CouponInput) {
    updateCoupon(coupon: $coupon) {
      id
    }
  }
`;

const getNumber = (prop: number | undefined) => {
  if (prop || prop === 0) return prop;
  else return '';
};

const ResponsiveWrapper = isMobile ? Row : React.Fragment;
const Offers = (props: PropsWithChildren) => {
  const offerCtx = useContext(OfferContext);
  const loadingCtx = useContext(LoadingContext);
  const offer = (offerCtx!.offer as any) || {};
  const isEditing = offer.id;
  const ctx = useContext(EmployeeContext);
  const dataRes = useQuery(DATA);
  const clubsResult = useQuery(BUSINESS_QUERY);

  const [insertOffer] = useMutation(SUBMIT_OFFER);
  const [deleteOffer] = useMutation(DELETE_OFFER);
  const [updateOffer] = useMutation(EDIT_OFFER);
  const cities = (dataRes.data && dataRes.data.cities) || [];
  const categories = ((dataRes.data && dataRes.data.categories) as any[]) || [];
  const businesses = (clubsResult.data && clubsResult.data.businesses) || [];

  const [submitting, setSubmitting] = useState(false);
  const [city, setCity] = useState({ name: offer.city });
  const [type, setType] = useState({ name: offer.type || CouponTypes.QR });
  const [business, setBusiness] = useState<GQLBusiness>();
  const [featured, setFeatured] = useState(offer.featured || false);
  const [category, setCategory] = useState({ name: offer.category ? offer.category.name : '', id: offer.category ? offer.category.id : 0 });
  const [image, setImage] = useState(offer.image);
  const [startDate, setStartDate] = useState(offer.startDate ? moment(offer.startDate) : undefined);
  const [expirationDate, setExpirationDate] = useState(offer.expirationDate ? moment(offer.expirationDate) : undefined);
  const [validUntil, setValidUntil] = useState(offer.validUntil ? moment(offer.validUntil) : undefined);

  useEffect(() => {
    if (categories && categories.length >= 1) {
      setCategory(categories.find((c: any) => c.id === offer.couponCategoriesID));
    }
  }, [categories, offer.couponCategoriesID]);

  useEffect(() => {
    if (ctx!.employee.type === Types.SuperAdmin) {
      setBusiness(businesses.find((c: Club) => c.id === offer.businessID));
    }
  }, [businesses]);

  const isValid = (vals: any) => {
    if (!vals.title) return alert('The title is missing');
    if (!vals.description) return alert('The description is missing');
    if (!vals.terms) return alert('The terms is missing');
    if (!vals.location) return alert('The location is missing');
    if (!startDate) return alert('The start date field is missing');
    if (!expirationDate) return alert('The expiration date is missing');
    if (!validUntil) return alert('The valid until date is missing');
    if (!vals.quantity && vals.quantity !== 0) return alert('The quantity is missing');
    if (!category || category.id === 0) return alert('The category is missing');
    if (!image) return alert('The image is missing');
    if (!city) return alert('The city is missing');
    if (type.name === CouponTypes.Code && !vals.code) return alert('The coupon code is missing');
    return true;
  };

  const onCreate = async (vals: any, draft: boolean) => {
    if (submitting) return;
    setSubmitting(true);
    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await insertOffer({
          variables: {
            coupon: {
              image,
              draft,
              featured,
              title: vals.title,
              description: vals.description,
              terms: vals.terms,
              location: vals.location,
              expirationDate: expirationDate!.toISOString(),
              validUntil: validUntil!.toISOString(),
              startDate: startDate!.toISOString(),
              quantity: vals.quantity,
              businessID: business && business.id ? business.id : ctx!.selectedClub ? ctx!.selectedClub.id : null,
              couponCategoriesID: category.id,
              city: city.name,
              type: type.name,
              code: vals.code,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
        } else {
          loadingCtx!.setLoading(false);
        }
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onUpdate = async (vals: any, draft: boolean) => {
    if (submitting) return;
    setSubmitting(true);

    if (isValid(vals)) {
      try {
        loadingCtx!.setLoading(true);
        const res = await updateOffer({
          variables: {
            coupon: {
              image,
              draft,
              featured,
              id: offer.id,
              title: vals.title,
              description: vals.description,
              terms: vals.terms,
              location: vals.location,
              expirationDate: expirationDate!.toISOString(),
              validUntil: validUntil!.toISOString(),
              startDate: startDate!.toISOString(),
              quantity: vals.quantity,
              businessID: business && business.id ? business.id : ctx!.selectedClub ? ctx!.selectedClub.id : null,
              couponCategoriesID: category.id,
              city: city.name,
              type: type.name,
              code: vals.code,
            },
          },
        });

        if (res && res.data) {
          loadingCtx!.setLoading(false);
          setSubmitting(false);
          ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
        }
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const onDelete = async (vals: any) => {
    if (submitting) return;
    setSubmitting(true);
    if (window.confirm('Are you sure? The offer will be permanently deleted')) {
      try {
        loadingCtx!.setLoading(true);
        await deleteOffer({
          variables: {
            couponID: offer.id,
          },
        });
        loadingCtx!.setLoading(false);
        setSubmitting(false);
        ctx!.employee.type === Types.SuperAdmin ? history.replace('/admin') : history.replace('/');
      } catch (e) {
        console.error(e);
        alert('Something went wrong');
        setSubmitting(false);
        loadingCtx!.setLoading(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Page>
      <NavBar />
      <Container>
        <Title>{isEditing ? 'Edit ' : 'Create '} offer</Title>

        <Formik
          initialValues={{
            title: offer.title || '',
            description: offer.description || '',
            terms: offer.terms || '',
            location: offer.location || '',
            quantity: getNumber(offer.quantity),
            code: offer.code || '',
          }}
          onSubmit={() => null}>
          {({ values, handleChange }) => (
            <Content>
              <Row>
                <Half>
                  <TextInput autocomplete="off" dark label="OFFER TITLE" name="title" value={values.title} onChange={handleChange} />
                </Half>
                <Half>
                  <Quarter>
                    <Select data={cities} label="City" keyProp="name" onSelect={(i: any) => setCity(i)} selectedValue={city} />
                  </Quarter>
                  <Quarter>
                    <Select data={categories} label="Category" keyProp="name" onSelect={(i: any) => setCategory(i)} selectedValue={category} />
                  </Quarter>
                  <Quarter>
                    <TextInput
                      autocomplete="off"
                      dark
                      label="NUMBER AVAILABLE"
                      name="quantity"
                      type="number"
                      value={values.quantity}
                      onChange={handleChange}
                      min={0}
                    />
                  </Quarter>
                </Half>
              </Row>

              <Row>
                <Half>
                  <TextInput autocomplete="off" dark label="OFFER LOCATION" name="location" value={values.location} onChange={handleChange} />
                </Half>

                <Half>
                  <Quarter>
                    <Select
                      data={[{ name: CouponTypes.QR }, { name: CouponTypes.Code }]}
                      label="Type"
                      keyProp="name"
                      onSelect={(i: any) => setType(i)}
                      selectedValue={type}
                    />
                  </Quarter>

                  {type.name === CouponTypes.Code && (
                    <TextInput autocomplete="off" dark label="OFFER CODE" name="code" value={values.code} onChange={handleChange} />
                  )}
                </Half>
              </Row>
              <Row>
                <Half>
                  <TextArea autocomplete="off" dark label="OFFER DESCRIPTION" name="description" onChange={handleChange} value={values.description} />
                </Half>

                <Half>
                  <TextArea autocomplete="off" dark label="OFFER TERMS AND CONDITIONS" name="terms" onChange={handleChange} value={values.terms} />
                </Half>
              </Row>

              <Row>
                <Half style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                  <ResponsiveWrapper>
                    <DateTime
                      dark
                      label="START DATE"
                      name="startDate"
                      onlyDate
                      value={startDate && startDate.format ? startDate.format('DD-MM-YYYY') : ''}
                      onChange={(d: moment.Moment) => setStartDate(d)}
                    />
                  </ResponsiveWrapper>

                  <ResponsiveWrapper>
                    <DateTime
                      dark
                      label="END DATE"
                      name="expirationDate"
                      onlyDate
                      value={expirationDate && expirationDate.format ? expirationDate.format('DD-MM-YYYY') : ''}
                      onChange={(d: moment.Moment) => setExpirationDate(d)}
                    />
                  </ResponsiveWrapper>
                  <ResponsiveWrapper>
                    <DateTime
                      dark
                      label="VALID UNTIL"
                      name="validUntil"
                      onlyDate
                      value={validUntil && validUntil.format ? validUntil.format('DD-MM-YYYY') : ''}
                      onChange={(d: moment.Moment) => setValidUntil(d)}
                    />
                  </ResponsiveWrapper>
                </Half>
                <Half>
                  <ImagePicker onChange={(v) => setImage(v)} image={image} />
                </Half>
              </Row>
              <Row>
                <Half>
                  {ctx!.employee.type === Types.SuperAdmin && (
                    <Select
                      width={500}
                      data={businesses}
                      label="Business"
                      keyProp="name"
                      onSelect={(i: any) => setBusiness(i)}
                      selectedValue={business}
                    />
                  )}
                  {ctx!.employee.type === Types.SuperAdmin && (
                    <LabelContent>
                      <Checkbox checked={featured} type="checkbox" id={'featured'} onChange={(e) => setFeatured(e.target.checked)} />
                      <Label htmlFor={'featured'}>Featured</Label>
                    </LabelContent>
                  )}
                </Half>
              </Row>
              <Row>
                {isEditing && offer.draft && (
                  <Quarter style={{ marginRight: isMobile ? 25 : 8 }}>
                    <Button text="DELETE" onClick={() => onDelete(values)} colour={colours.red} style={{ height: 40, width: '100%' }} />
                  </Quarter>
                )}
                <Quarter style={{ marginRight: isMobile ? 25 : 8 }}>
                  {!isEditing && <Button text="SAVE DRAFT" onClick={() => onCreate(values, true)} style={{ height: 40, width: '100%' }} />}
                  {isEditing && !offer.draft && (
                    <Button text="DELETE" onClick={() => onDelete(values)} colour={colours.red} style={{ height: 40, width: '100%' }} />
                  )}
                  {isEditing && offer.draft && (
                    <Button text="UPDATE DRAFT" onClick={() => onUpdate(values, true)} style={{ width: '100%', height: 40 }} />
                  )}
                </Quarter>
                <Quarter>
                  {isEditing && !offer.draft && (
                    <Button text="UPDATE" onClick={() => onUpdate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                  {isEditing && offer.draft && (
                    <Button text="PUSH LIVE" onClick={() => onUpdate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                  {!isEditing && (
                    <Button text="PUSH LIVE" onClick={() => onCreate(values, false)} colour={colours.green} style={{ width: '100%', height: 40 }} />
                  )}
                </Quarter>
              </Row>
            </Content>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default Offers;
