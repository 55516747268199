import styled from 'styled-components';
import { colours, corners, fonts, spacing } from '../../styles/theme';

export const Container = styled.div<{ width?: number }>`
  width: ${(p) => (p.width ? p.width : 200)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: none;
  position: relative;
`;

export const Opener = styled.span<{ isOpen?: boolean }>`
  text-align: center;
  width: 100%;
  height: 40px;
  border: 0;
  outline: none;
  padding: ${spacing}px;
  border-radius: ${corners}px;
  border-bottom-right-radius: ${(p) => (p.isOpen ? 0 : corners)}px;
  border-bottom-left-radius: ${(p) => (p.isOpen ? 0 : corners)}px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: white;
  font-family: ${fonts.bold};
  color: ${colours.blue};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  height: ${(p) => (p.isOpen ? 'inherit' : 0)}px;
  max-height: 150px;
  position: absolute;
  top: 40px;
  overflow-y: auto;
  border: 0;
  outline: none;
  padding-top: ${(p) => (p.isOpen ? spacing : 0)}px;
  padding-bottom: ${(p) => (p.isOpen ? spacing : 0)}px;
  border-radius: ${corners}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: white;
  font-family: ${fonts.bold};
  color: ${colours.blue};
  cursor: pointer;
  z-index: 4000;
`;

export const Item = styled.span<{ isSelected: boolean }>`
  width: 100%;
  height: 35px;
  color: ${(p) => (p.isSelected ? colours.white : colours.blue)};
  display: flex;
  font-family: ${(p) => (p.isSelected ? fonts.bold : fonts.regular)};
  align-items: center;
  padding: ${spacing}px;
  background: ${(p) => (p.isSelected ? colours.blue : colours.white)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TopLabel = styled.label`
  font-family: BrandonGrotesque-Bold;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  position: absolute;
  left: 8px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: none;
  color: #4a5968;
  transform: translateY(-32px);
`;
