export class PaymentHelper {
  /*
   * What the user will pay
   * Event price * quantity + booking fee * quantity + stripe fixed + stripe percent + vat
   */
  public static calculateUserPrice(cents: number, quantity: number = 1): number {
    const goal = this.addFee(cents, quantity);
    return Math.round((goal + this.stripeFixed * 1.23) / (1 - this.stripePercent * 1.23));
  }

  // What the user will pay plus, how much stripe takes and how much we take
  public static calculateWithDetail(eventCents: number, quantity: number): { stripeFee: number; userPaid: number; bookingFee: number } {
    const total = eventCents * quantity;
    const totalFee = this.getBookingFee(eventCents) * quantity; // The user pays 1 booking fee per ticket that they buy
    const userPaid = this.calculateUserPrice(eventCents, quantity);
    const stripeFee = Math.round(userPaid - total - totalFee);

    return { stripeFee, userPaid, bookingFee: totalFee };
  }

  // Formats the pice with 2 decimals.
  public static getPriceFormatted(cents: number): string {
    const euros = cents / 100;
    const decimals = cents % 100 === 0 ? 0 : 2;
    return euros.toFixed(decimals);
  }

  public static getPriceForApp(eventPrice: number, quantity: number = 1) {
    return this.getPriceFormatted(this.calculateUserPrice(eventPrice, quantity));
  }

  public static getBookingFee(eventPrice: number): number {
    const actualFee = eventPrice * 0.125; // 12.5%
    if (actualFee < PaymentHelper.minimumFee) {
      return PaymentHelper.minimumFee;
    } else {
      return Math.round(actualFee);
    }
  }

  private static stripeFixed = 25; // cents
  private static stripePercent = 0.014; // 1.4%
  private static minimumFee = 100; // 1 euro inc VAT
  private static addFee(eventPrice: number, quantity: number): number {
    return eventPrice * quantity + this.getBookingFee(eventPrice) * quantity;
  }
}
