import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colours, spacing } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95vw;
  max-width: 1200px;
  z-index: 300;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colours.blue}17;
  padding: ${spacing * (isMobile ? 2 : 5)}px;
  ${isMobile &&
  css`
    padding-top: ${spacing * 3}px;
  `}
`;

export const Half = styled.div`
  width: ${!isMobile ? '50%' : '100%'};
  &:not(:last-of-type) {
    margin-right: ${isMobile ? 0 : spacing}px;
  }
  display: flex;

  ${isMobile
    ? css`
        margin-bottom: ${spacing * 3}px;
      `
    : ''}
`;

export const Quarter = styled.div`
  width: 25%;
  max-width: 133px;
  &:not(:last-of-type) {
    margin-right: ${spacing}px;
  }
  display: flex;
`;

export const LabelContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: #4a5968;
  font-family: BrandonGrotesque-Bold;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  left: 8px;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

export const Checkbox = styled.input`
  margin: 0 0.5rem;
  margin-top: -0.4rem;
`;
