import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';

const httpLink = new HttpLink({ uri: process.env.NODE_ENV === 'production' ? ' /graphql' : 'http://localhost:3005/graphql' });
const authLink = setContext(async (_: any, { headers }: any) => {
  const token = await localStorage.getItem('jwtToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export const apolloClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

const client = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/v4' : 'http://localhost:3005/v4',
});

client.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem('jwtToken')}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export const version = 'v4';
export default client;
