import { isMobile } from 'react-device-detect';

const eHeaders = [
  { width: '5%', name: '' },
  { width: '30%', name: '' },
  { width: '8.5%', name: 'guest list' },
  { width: '8.5%', name: 'FIRST RELEASE' },
  { width: '10%', name: 'SECOND RELEASE' },
  { width: '8.5%', name: 'THIRD RELEASE' },
  { width: '8.5%', name: 'TOTAL' },
  { width: '8.5%', name: 'PAY OUT' },
  { width: '13.5%', name: 'DATE' },
];

const oHeaders = [
  { width: '5%', name: '' },
  { width: '30%', name: '' },
  { width: '13%', name: 'AVAILABLE' },
  { width: '13%', name: 'CLAIMED' },
  { width: '13%', name: 'REDEEMED' },
  { width: '13%', name: 'EXPIRES' },
  { width: '13%', name: 'LOCATION' },
];

const eMobileHeaders = [
  { width: '5%', name: '' },
  { width: '40%', name: '' },
  { width: '18.3%', name: 'TOTAL' },
  { width: '18.3%', name: 'PAY OUT' },
  { width: '18.3%', name: 'DATE' },
];

const oMobileHeaders = [
  { width: '5%', name: '' },
  { width: '30%', name: '' },
  { width: '16.5%', name: 'AVAILABLE' },
  { width: '16.5%', name: 'CLAIMED' },
  { width: '16.5%', name: 'REDEEMED' },
  { width: '16.5%', name: 'EXPIRES' },
];

export const getHeaders = () => ({ events: isMobile ? eMobileHeaders : eHeaders, offers: isMobile ? oMobileHeaders : oHeaders });
