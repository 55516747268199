import React, { createContext, useState } from 'react';
import { Admin, Club, Employee } from '../shared';

interface IState {
  employee: Employee;
  auth: boolean;
  setEmployee: (employee: Employee | Admin | null) => void;
  setAuth: (auth: any) => void;
  setSelectedClub: (c: Club | null) => void;
  selectedClub: Club;
}

export const EmployeeContext = createContext<IState | null>(null);

const EmployeeProvider = (props: any) => {
  const [employee, setEmployee] = useState<Employee | Admin | null>(null);
  const [selectedClub, setSelectedClub] = useState<Club | null>(null);
  const [auth, setAuth] = useState<boolean>(false);
  return (
    <EmployeeContext.Provider
      value={{
        employee,
        auth,
        setAuth,
        selectedClub,
        setSelectedClub,
        setEmployee: (e: Employee) => {
          setEmployee(e);
          if (e) {
            setSelectedClub(e.clubs[0]);
          }
        },
      }}
      {...props}
    />
  );
};
export default EmployeeProvider;
