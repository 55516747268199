import React, { PropsWithChildren, ReactElement } from 'react';
import { Col, Container, Headers, HeaderText, Rows } from './style';

export interface IHeader {
  width: string;
  name: string;
}

interface IProps {
  headers: IHeader[];
  renderRows: () => ReactElement;
}
const Button = (props: PropsWithChildren<IProps>) => {
  return (
    <Container>
      <Headers>
        {props.headers.map((h) => (
          <Col key={`${h.name}${h.width}`} style={{ width: h.width }} noBorder>
            <HeaderText>{h.name}</HeaderText>
          </Col>
        ))}
      </Headers>
      <Rows>{props.renderRows()}</Rows>
    </Container>
  );
};

export default Button;
