import React, { useImperativeHandle, useState } from 'react';
import { Container, Message } from './style';
interface IProps {
  message: string;
  ref: any;
  type: string;
}
export interface IPopupBase {
  open: () => void;
  close: () => void;
}
const BasePopUp = React.forwardRef((props: IProps, ref: any) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  return (
    <Container type={props.type} style={{ display: open ? 'flex' : 'none' }}>
      <Message>{props.message}</Message>
    </Container>
  );
});

export default BasePopUp;
