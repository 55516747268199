import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../hooks/useClickOutside';
import { Container, Content, Item, Opener, TopLabel } from './style';

type IProps = {
  multiple?: boolean;
  data: any[];
  selectedValue?: any;
  keyProp: string;
  label: string;
  onSelect: any;
  width?: number;
};

const Select = (props: PropsWithChildren<IProps>) => {
  const { data, keyProp, label, onSelect, width, multiple = false } = props;
  const [selectedValue, setSelectedValue] = useState(props.selectedValue || { [keyProp]: '' });
  const [isOpen, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    props.selectedValue && props.selectedValue[0] && props.selectedValue[0][keyProp] ? props.selectedValue : [],
  );

  const ref = useRef<HTMLDivElement>();

  useOnClickOutside(ref, () => setOpen(false));
  const onItemSelect = (i: any) => {
    if (!multiple) {
      setSelectedValue(i);
      setOpen(false);
      onSelect(i);
    } else {
      const actualValues = selectedValues && selectedValues[0] && selectedValues[0].name ? [...selectedValues] : [];

      if (selectedValues.findIndex((e: any) => e[keyProp] === i[keyProp]) !== -1) {
        actualValues.splice(
          selectedValues.findIndex((e: any) => e[keyProp] === i[keyProp]),
          1,
        );
      } else {
        actualValues.push(i);
      }

      setSelectedValues(actualValues);
      setOpen(false);
      onSelect(actualValues);
    }
  };

  useEffect(() => {
    setSelectedValue(props.selectedValue);
  }, [props.selectedValue]);

  const getText = () => {
    if (selectedValue && selectedValue[keyProp]) return selectedValue[keyProp].toUpperCase();
    else if (selectedValues.length) return `${selectedValues.length} items selected`;
    else return label.toUpperCase();
  };

  return (
    <Container ref={(r) => (ref.current = r!)} width={width}>
      {((selectedValue && selectedValue[keyProp]) || selectedValues.length !== 0) && <TopLabel>{label.toUpperCase()}</TopLabel>}
      <Opener isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        {getText()}
      </Opener>
      <Content isOpen={isOpen}>
        {data.map(
          (i) =>
            i[keyProp] && (
              <Item
                key={i[keyProp]}
                isSelected={
                  !multiple
                    ? selectedValue && selectedValue[keyProp] === i[keyProp]
                    : selectedValues.findIndex((e: any) => e[keyProp] === i[keyProp]) !== -1
                }
                onClick={() => onItemSelect(i)}>
                {i[keyProp]}
              </Item>
            ),
        )}
      </Content>
    </Container>
  );
};
export default Select;
